import endpoints from "api/endpoints";
import getAudioContext from "components/dataLabeling/audio/getAudioContext";
import axiosNeuron from "api/axios";
import { UTC } from "components/dataLabeling/constants";
import { FLACDecoder } from "@wasm-audio-decoders/flac";
import { create, ConverterType } from "@alexanderolsen/libsamplerate-js";

export async function getAudio(
  placement: number,
  start: Date | string,
  end: Date | string
) {
  const ctx = getAudioContext();

  const from = new Date(start);
  const to = new Date(end);

  const url = endpoints.audio.default;
  let data: any;

  try {
    const { data } = await axiosNeuron.get(
      `${url}?placement=${encodeURIComponent(
        placement
      )}&start_time=${from.toISOString()}&end_time=${to.toISOString()}&tz=${encodeURIComponent(
        UTC
      )}`,
      { responseType: "arraybuffer" }
    );
    try {
      // try to decode with FLACDecoder and resample with libsamplerate-js
      const decoder = new FLACDecoder();
      await decoder.ready; // wait for the WASM to be compiled
      const byte_data = new Uint8Array(data);
      const { channelData, sampleRate } = await decoder.decodeFile(byte_data);
      decoder.free();
      if (sampleRate >= 8000 && sampleRate <= 96000) {
        const ret = ctx.createBuffer(1, channelData[0].length, sampleRate);
        ret.getChannelData(0).set(channelData[0]);
        return ret;
      } else {
        const newSampleRate = sampleRate < 8000 ? 8000 : 96000;
        const resampler = new Promise((resolve, reject) => {
          create(1, sampleRate, newSampleRate, {
            converterType: ConverterType.SRC_SINC_BEST_QUALITY,
          }).then((src) => {
            const resampled = src.simple(channelData[0]);
            src.destroy();
            resolve(resampled);
          });
        });
        const resampled: any = await resampler;
        const ret = ctx.createBuffer(1, resampled.length, newSampleRate);
        ret.getChannelData(0).set(resampled);
        return ret;
      }
    } catch (e) {
      return await ctx.decodeAudioData(data);
    }
  } catch (err) {
    if (
      data?.message &&
      data?.message.match(
        /System error: index .* is out of bounds for axis .* with size .*/
      )
    ) {
      return null;
    }
  }
}

import { Box, Grid } from "@mui/material";
import { ModalWrap } from "components/events/components/modalWrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AnalysisTimeManager, {
  AnalysisTimeManagerData,
} from "components/analysisTimeManager/analysisTimeManager";
import analysisTimeManagerActions from "store/machineDetail/analysisTimeManager/actions";

const { setData: setAnalysisTimeManagerData } = analysisTimeManagerActions;

type TProps = {
  open: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
  analysisTimeManagerDataChange: AnalysisTimeManagerData | null | undefined;
  setAnalysisTimeManagerDataChange: (
    value: React.SetStateAction<AnalysisTimeManagerData | null | undefined>
  ) => void;
};

const AnalaysisTimeManagerModal = ({
  open,
  setModalOpen,
  analysisTimeManagerDataChange,
  setAnalysisTimeManagerDataChange,
}: TProps) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();

  return (
    <ModalWrap
      open={open}
      onCancel={() => setModalOpen(false)}
      title={t("analysisTimeManager.manageTime")}
      cta={t("confirmationBar.saveChanges")}
      submitDisabled={false}
      onSubmit={() => {
        dispatch(setAnalysisTimeManagerData(analysisTimeManagerDataChange));
        setModalOpen(false);
      }}
    >
      <Box flex={1} p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {analysisTimeManagerDataChange && (
              <AnalysisTimeManager
                data={analysisTimeManagerDataChange}
                setData={setAnalysisTimeManagerDataChange}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </ModalWrap>
  );
};
export default AnalaysisTimeManagerModal;

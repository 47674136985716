import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { LocalizationProvider } from "@mui/x-date-pickers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useStyles } from "components/events/styles";
import { useDispatch } from "react-redux";
import { actions } from "store/eventModal/slice";
import { EModalTypes, ELabelDateType } from "store/eventModal/types";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomPicker from "components/customPickers";

interface IStartDatePicker {
  date: string | undefined;
  type: ELabelDateType;
  disabled?: boolean;
  maxDate?: string | any;
  minDate?: string | any;
  error: string | null;
  validateRange: any;
  modal?: string;
  end?: boolean;
  eventType?: string | null;
  label: string;
}

export const DatePicker = memo(
  ({
    disabled,
    maxDate,
    error,
    minDate,
    type,
    modal,
    validateRange,
    end,
    eventType,
    label,
    date,
    ...props
  }: IStartDatePicker) => {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();

    const validateDate = useCallback(
      (date: any, valid: any, error: any) => {
        if (valid) {
          if (validateRange(date, type)) {
            dispatch(actions.setError({ type, error: null }));
          }
        } else {
          if (
            modal === EModalTypes.create &&
            type === ELabelDateType.end &&
            date === null
          ) {
            dispatch(actions.setError({ type, error: null }));
          } else {
            dispatch(actions.setError({ type, error }));
          }
        }
      },
      [dispatch, modal, type, validateRange]
    );

    const onChange = useCallback(
      (date: any) => {
        if (maxDate) {
          validateDate(date, date < maxDate, t("eventModal.errors.startAt"));
        } else if (minDate) {
          validateDate(date, date > minDate, t("eventModal.errors.endAt"));
        } else {
          if (validateRange(date, type)) {
            dispatch(actions.setError({ type, error: null }));
          }
        }
        dispatch(actions.setTime({ type, time: dayjs(date).format() }));
      },
      [
        maxDate,
        minDate,
        validateDate,
        validateRange,
        type,
        dispatch,
        end,
        t,
        date,
      ]
    );

    React.useEffect(() => {
      if (validateRange(date, type)) {
        dispatch(actions.setError({ type, error: null }));
      }
    }, [eventType, date, type, validateRange, dispatch]);

    return (
      <Box
        sx={{
          "& .MuiInputBase-root": {
            paddingRight: "15px",
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomPicker
            value={date ? dayjs(date).toDate() : null}
            onChange={onChange}
            id={label}
            label={label}
            showTimeSelect={true}
            dateFormat="MM/dd/yyyy HH:mm:ss"
            icon={<AccessTimeIcon />}
            maxDate={maxDate || undefined}
            minDate={minDate || undefined}
            helperText={!!error ? error : null}
            disabled={disabled}
          />
        </LocalizationProvider>
      </Box>
    );
  }
);

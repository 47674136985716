/*eslint no-useless-constructor: "error"*/
import React, { PureComponent } from "react";
import Tooltip from "@mui/material/Tooltip";
import { withTranslation } from "react-i18next";

class RegionViewMode extends PureComponent {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    let {
      style: suggestedStyle,
      left,
      bottom,
      height,
      width,
      region,
      id,
      t,
    } = this.props;

    let style = {
      left,
      bottom,
      width,
      height,
      position: "absolute",
      borderRadius: 3,
      display: "flex",
      flexDirection: "column",
    };

    let { labels, meta, start, end } = region;

    let tooltip = labels
      .slice(0, 10)
      .map(({ name }) => (name && name[0] === "$" ? name.slice(1) : name));
    if (tooltip.length === 0) {
      tooltip.push(t("taggingapp.labels.noLabels"));
    } else if (labels.length > 2) {
      tooltip.push(
        t("taggingapp.labels.moreLabels_interval", {
          postProcess: "interval",
          count: labels.length - 2,
        })
      );
    }

    return (
      <Tooltip
        title={
          meta ? (
            <div>
              {tooltip.join(", ")}
              <br />
              {t("taggingapp.labels.meta")}:{" "}
              <pre>
                {JSON.stringify(meta, null, 4)
                  .replace(/"([^"]+)"/g, "$1")
                  .replace("{", "")
                  .replace("}", "")}
              </pre>
            </div>
          ) : (
            <div>{tooltip.join(", ")}</div>
          )
        }
      >
        <div id={id} style={style}>
          {labels.map((item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
              }}
            >
              <span
                style={{
                  backgroundColor: item.color
                    ? `#${item.color}`
                    : suggestedStyle,
                  width: "1px",
                }}
              ></span>
              {start !== end ? (
                <>
                  <span
                    style={{
                      backgroundColor: item.color
                        ? `#${item.color}`
                        : suggestedStyle,

                      opacity: 0.3,
                      height: "100%",
                      width: "100%",
                    }}
                  ></span>
                  <span
                    style={{
                      backgroundColor: item.color
                        ? `#${item.color}`
                        : suggestedStyle,
                      width: "1px",
                    }}
                  ></span>
                </>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </Tooltip>
    );
  }
}

export default withTranslation()(RegionViewMode);

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import { fetchProjectMachines } from "store/projectDetail/machines/actions";
import actions from "store/projectDetail/temporaryToken/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { Box, InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IMachineList } from "types/machine";
import Tag from "components/tag";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import { BlackCheckbox } from "components/checkbox";

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
  },

  renderWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(-1.25),
  },
  renderWrapBox: {
    maxWidth: "100%",
  },
  last: { maxWidth: "50%" },
  andMoreText: {
    maxWidth: "30%",
    color: theme.custom.palette.label,
    fontFamily: theme.custom.typography.fontFamily,
    fontSize: theme.custom.typography.fontSize["11"],
    fontWeight: "bold",
    display: "inline-block",
    padding: ".1875rem .5rem .125rem .25rem",
    textTransform: "uppercase",
    letterSpacing: ".04rem",
    lineHeight: 1.2,
  },
  searchIcon: {
    color: theme.custom.palette.placeholder,
  },
  textField: {
    height: theme.spacing(4),
  },
  searchInput: {
    fontSize: theme.custom.typography.fontSize[14],
    fontWeight: theme.custom.typography.fontWeight.normal,
    color: theme.custom.palette.data,
    letterSpacing: theme.custom.typography.letterSpacing.small,
    "&::placeholder": {
      fontSize: theme.custom.typography.fontSize[14],
      fontWeight: theme.custom.typography.fontWeight.normal,
      color: theme.custom.palette.placeholder,
      letterSpacing: theme.custom.typography.letterSpacing.small,
    },
  },
  searchBox: {
    boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} -${theme.spacing(
      0.5
    )} ${theme.custom.palette.shadow}`,
  },
}));

interface IProps {
  machineIds: number[];
  projectId: number;
}

function MultiSelect({ machineIds, projectId }: IProps) {
  const dispatch: any = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<any>(machineIds);
  const [isOpen, setIsOpen] = useState(false);
  const inputLabel = useRef<HTMLLabelElement>(null);

  const { machines, loading } = useSelector(
    (state: AppState) => state.projectDetail.machines
  );

  const options = machines?.results.map((machine: IMachineList) => ({
    value: machine.id,
    text: machine.name,
  }));

  const [searchPhrase, setSearchPhrase] = useState("");

  const filterOptions = (options: any, searchPhrase: string) => {
    const outputOptions: any = [];
    options?.forEach((option: any) => {
      if (option?.text.toLowerCase().includes(searchPhrase.toLowerCase())) {
        outputOptions.push(option);
      }
    });
    return outputOptions;
  };

  const handleSearch = () => {
    setSearchPhrase(searchPhrase);
  };

  const handleChange = (event: any) => {
    const value = event.target.value;

    setSelectedValues(value);
  };

  const handleBlur = useCallback(
    (selectedValues: any) => {
      dispatch(actions.setMachines(selectedValues));
      setIsOpen(false);
      setSearchPhrase("");
    },
    [dispatch]
  );

  const renderTags = useCallback(
    (selected: any) => {
      const MAX_ITEM_COUNT = 5;
      const usedOptions: any = (selected as string[])
        .map((selectedValue: any) => {
          for (let i = 0; i < options?.length; i++) {
            if (options[i].value === selectedValue) {
              return options[i].text;
            }
          }
          return null;
        })
        .filter((e: any) => e);

      const tagsToFit = usedOptions.reduce(
        (array: string[], option: string) => {
          if (array?.length < MAX_ITEM_COUNT) {
            array.push(option);
          }
          return array;
        },
        [] as string[]
      );

      return (
        <div className={classes.renderWrap}>
          {(tagsToFit as string[]).map((option, index) => {
            return (
              <Box
                mr={1}
                mb={1}
                key={index}
                className={clsx(
                  classes.renderWrapBox,
                  tagsToFit?.length - 1 === index &&
                    tagsToFit?.length >= MAX_ITEM_COUNT &&
                    classes.last
                )}
              >
                <Tag color={false} ellipsis>
                  {option}
                </Tag>
              </Box>
            );
          })}
          {usedOptions > tagsToFit ? (
            <Box mr={1} mb={1.5} className={classes.andMoreText}>
              {t("multiselect.andMore", {
                postProcess: "interval",
                count: usedOptions?.length - tagsToFit?.length,
              })}
            </Box>
          ) : null}
        </div>
      );
    },
    [classes, options, t]
  );

  const renderOptions = useMemo(() => {
    return (
      isOpen &&
      filterOptions(options, searchPhrase).map((option: any) => (
        <MenuItem key={option?.value} value={option?.value}>
          <ListItemIcon>
            <BlackCheckbox
              checked={selectedValues.indexOf(option?.value) > -1}
            />
          </ListItemIcon>
          <ListItemText primary={option?.text} />
        </MenuItem>
      ))
    );
  }, [isOpen, options, searchPhrase, selectedValues]);

  useEffect(() => {
    dispatch(fetchProjectMachines(projectId));
  }, [dispatch]);

  return (
    <FormControl variant="outlined" size="small" className={classes.root}>
      <InputLabel ref={inputLabel} htmlFor="machine-select">
        {t("temporaryToken.list.table.editDialog.selectMachines")}
      </InputLabel>
      <Select
        id="machine-select"
        labelId="machine-select"
        label={t("temporaryToken.list.table.editDialog.selectMachines")}
        multiple
        value={selectedValues}
        onChange={handleChange}
        renderValue={renderTags}
        open={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          onClose: () => handleBlur(selectedValues),
        }}
      >
        <Box p={2} pt={1} mb={2} className={classes.searchBox}>
          <TextField
            placeholder={t(
              "temporaryToken.list.table.editDialog.search.placeholder"
            )}
            id={"machine-select-SearchInput"}
            className={classes.textField}
            fullWidth
            variant="outlined"
            onClick={(e: any) => e.stopPropagation()}
            value={searchPhrase}
            onChange={(e: any) => {
              setSearchPhrase(e.target.value);
            }}
            onKeyDown={(e: any) => {
              e.stopPropagation();
            }}
            InputProps={{
              classes: {
                input: classes.searchInput,
              },
              startAdornment: (
                <InputAdornment position="start" onClick={handleSearch}>
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {loading ? <Spinner /> : renderOptions}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;

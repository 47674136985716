import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function getMachineModelTypes({
  product_line,
}: {
  product_line: number | null;
}) {
  const url = endpoints.machines.machineModelTypes;
  try {
    const { data } = await axiosNeuron.get(url, {
      params: {
        product_line: product_line ? product_line : undefined,
      },
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

import React, { memo, useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { KebabMenu } from "components/kebab";
import ConfirmPopup from "components/confirmPopup";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";
import actions, {
  deleteTemporaryToken,
  udpateTemporaryToken,
} from "store/projectDetail/temporaryToken/actions";
import { TokenModal } from "components/projects/detail/modal/components/temporaryToken";
import { enqueueSnackbar } from "notistack";
import moment from "moment";

const useStyles = makeStyles((theme: any) => {
  return {
    item: {
      fontSize: theme.custom.typography.fontSize[14],
      color: theme.custom.palette.data,
      fontWeight: theme.custom.typography.fontWeight.normal,
    },
  };
});

export const ActionsButton = memo(
  ({
    cell: {
      value: { id, expires_at, key, expiresAt, machineIds, machines, project },
    },
  }: any) => {
    const dispatch: any = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const disableEdit = usePermissions([
      EPermissions.UPDATE_OWN_TEMPORARY_TOKEN,
      EPermissions.UPDATE_ANY_TEMPORARY_TOKEN,
    ]);
    const disableDelete = usePermissions([
      EPermissions.DELETE_OWN_TEMPORARY_TOKEN,
      EPermissions.DELETE_ANY_TEMPORARY_TOKEN,
    ]);

    const hideKebabMenu = useCallback(() => {
      const hideKebab = new CustomEvent("hideKebab");
      const kebab = document.querySelector("#kebab-devices-list-" + id);
      kebab?.dispatchEvent(hideKebab);
    }, [id]);

    const onEdit = useCallback(() => {
      hideKebabMenu();
      setEditModal(!editModal);
    }, [hideKebabMenu, id]);

    const onDelete = useCallback(() => {
      hideKebabMenu();
      setDeleteModal(true);
    }, [hideKebabMenu, id]);

    const closeMenu = useCallback(() => {
      setDeleteModal(false);
    }, []);

    const deleteToken = useCallback(() => {
      setDeleteModal(false);
      dispatch(deleteTemporaryToken({ id }));
    }, [hideKebabMenu, dispatch, id]);

    // Get current url
    const currentUrl = window.location.href;
    const baseUrl = new URL(currentUrl).origin;
    const onCopyLink = useCallback(async () => {
      hideKebabMenu();
      try {
        const linkToCopy = `${baseUrl}/temporary-access/?token=${key}`;

        await navigator.clipboard.writeText(linkToCopy);

        enqueueSnackbar(t("projects.detail.temporaryToken.copy.success"));
      } catch (error) {
        enqueueSnackbar(t("projects.detail.temporaryToken.copy.error"));
      }
    }, [hideKebabMenu, id]);

    const onCancel = useCallback(() => {
      setEditModal(false);
      dispatch(actions.setExpiresAt(null));
      dispatch(actions.setReadOnly(false));
      dispatch(actions.setMachines([]));
    }, [dispatch]);

    const onSubmitEvent = useCallback(() => {
      setEditModal(false);

      dispatch(
        udpateTemporaryToken(id, {
          expires_at: expiresAt,
          machines: machineIds,
        })
      );
    }, [dispatch, expiresAt, id, machineIds]);

    const currentDate = moment();
    const dateToCheck = moment(expires_at);

    const isactive = dateToCheck.isBefore(currentDate);

    const generateItems = useMemo(() => {
      return (
        <>
          <MenuItem
            disabled={!disableEdit}
            className={classes.item}
            onClick={onEdit}
          >
            {t("temporaryToken.list.table.edit")}
          </MenuItem>
          <MenuItem
            disabled={!disableDelete}
            className={classes.item}
            onClick={onDelete}
          >
            {t("temporaryToken.list.table.delete")}
          </MenuItem>
          {!isactive ? (
            <MenuItem className={classes.item} onClick={onCopyLink}>
              {t("temporaryToken.list.table.copyLink")}
            </MenuItem>
          ) : null}
        </>
      );
    }, [
      classes.item,
      disableDelete,
      disableEdit,
      isactive,
      onCopyLink,
      onDelete,
      onEdit,
      t,
    ]);

    return (
      <>
        <KebabMenu id={"kebab-devices-list-" + id} data={generateItems} />

        {editModal ? (
          <TokenModal
            open={editModal}
            onCancel={onCancel}
            onSubmitEvent={onSubmitEvent}
            date={expiresAt}
            type={"expiresAtUpdate"}
            label={t("temporaryToken.list.table.editDialog.expiresAtLabel")}
            id={id}
            expires_at={expires_at}
            machineIds={machines}
            projectId={project}
          />
        ) : null}
        {deleteModal && (
          <ConfirmPopup
            onConfirm={deleteToken}
            title={t("temporaryToken.titles.delete")}
            text={t("temporaryToken.titles.deleteText")}
            confirmText={t("temporaryToken.actions.delete")}
            noControl={true}
            onCancel={closeMenu}
          />
        )}
      </>
    );
  }
);

import { useCallback } from "react";
import urls from "shared/appUrls";
import List from "@mui/material/List";
import { useTranslation } from "react-i18next";
import { logout } from "store/auth/actions";
import MenuItem from "components/menuItem/index";
import HomeIcon from "@mui/icons-material/Home";
import UsersIcon from "@mui/icons-material/SupervisedUserCircleRounded";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import MemoryIcon from "@mui/icons-material/Memory";
import SupportIcon from "assets/muiSvgIcons/support";
import ExploreIcon from "@mui/icons-material/Explore";
import WorkIcon from "@mui/icons-material/Work";
import EmojiSymbolsIcon from "@mui/icons-material/EmojiSymbols";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Box from "@mui/material/Box";
import UserBox from "components/userBox/index";
import Divider from "components/typography/heading/divider";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { getTranslatedRole } from "shared/roles";
import EPermissions from "shared/permissions";
import AuthorizedBlock from "components/authorizedBlock";
import usePermissions from "shared/hooks/usePermissions";
import Button from "components/button";
import { changeLanguage } from "shared/language";
import Settings from "@mui/icons-material/Settings";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Props {
  innerStyle?: string;
  onDrawerToggle?: () => void;
  opened?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    list: {
      [theme.breakpoints.only("sm")]: {
        padding: theme.spacing(5.25, 0, 1),
      },
    },
    sidebarInner: {
      margin: ({ opened }: Props) => (opened ? theme.spacing(0, 2) : 0),
      textAlign: ({ opened }: Props) => (opened ? "inherit" : "center"),
      [theme.breakpoints.between("sm", "md")]: {
        margin: 0,
        textAlign: "center",
      },
    },
  };
});

const Menu = ({ opened, ...props }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ opened, ...props });
  const user = useSelector((state: AppState) => state.auth.user);
  const breakpointSmMd = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const dispatch: any = useDispatch();
  const { t, i18n } = useTranslation();
  const { onDrawerToggle } = props;

  const authorizedReadUser = usePermissions([
    EPermissions.READ_ANY_USER,
    EPermissions.READ_OWN_USER,
  ]);

  const logOut = useCallback(() => {
    dispatch(logout(false));
  }, [dispatch]);

  const showTranslationKeys = useCallback(() => {
    if (i18n.language === "cimode") {
      changeLanguage("en_US");
    } else {
      changeLanguage("cimode");
    }
  }, [i18n.language]);
  const isAdmin = user?.role === "nsw_admin";

  return (
    <>
      {!breakpointSmMd && opened && (
        <div className={classes.sidebarInner}>
          <Box mt={5.25} mb={1}>
            <Divider noMargin>{t("manage")}</Divider>
          </Box>
        </div>
      )}
      <List className={classes.list}>
        <MenuItem
          opened={opened}
          icon={<HomeIcon />}
          text={t("menu.dashboard")}
          to={urls.appRoot.index()}
          onClick={onDrawerToggle}
          path="machineFilter"
          exact
        />
        {user && !user.isInternal ? (
          <AuthorizedBlock
            authorizedTo={[
              EPermissions.LIST_OWN_COMPANIES,
              EPermissions.LIST_ALL_COMPANIES,
            ]}
          >
            <MenuItem
              opened={opened}
              icon={<WorkIcon />}
              text={t("menu.companiesAndProjects")}
              to={urls.companies.index()}
              onClick={onDrawerToggle}
              path="companiesList"
            />
          </AuthorizedBlock>
        ) : null}
        {user && !user.isInternal ? (
          <MenuItem
            opened={opened}
            icon={<MemoryIcon />}
            text={t("menu.devices")}
            to={urls.devices.index()}
            onClick={onDrawerToggle}
            path="devicesList"
          />
        ) : null}

        {user && !user.isInternal ? (
          <AuthorizedBlock
            authorizedTo={[
              EPermissions.UPDATE_OWN_USER,
              EPermissions.UPDATE_ANY_USER,
            ]}
          >
            <MenuItem
              opened={opened}
              icon={<UsersIcon />}
              text={t("menu.users")}
              to={urls.users.index()}
              onClick={onDrawerToggle}
              path="users"
            />
          </AuthorizedBlock>
        ) : null}

        <AuthorizedBlock authorizedTo={[EPermissions.UPDATE_ANY_USER]}>
          <MenuItem
            opened={opened}
            icon={<EmojiSymbolsIcon />}
            text={t("menu.models")}
            to={urls.models.detail()}
            onClick={onDrawerToggle}
          />
        </AuthorizedBlock>
        <AuthorizedBlock
          authorizedTo={[EPermissions.LIST_ALL_TEMPORARY_TOKENS]}
        >
          <MenuItem
            opened={opened}
            icon={<VpnKeyIcon />}
            text={t("menu.accessTokens")}
            to={urls.accessTokens.index()}
            onClick={onDrawerToggle}
          />
        </AuthorizedBlock>
        {isAdmin ? (
          <MenuItem
            opened={opened}
            icon={<ExploreIcon />}
            text={t("menu.eventSounds")}
            to={urls.eventSounds.detail()}
            onClick={onDrawerToggle}
          />
        ) : null}

        {isAdmin ? (
          <MenuItem
            opened={opened}
            icon={<Settings />}
            text={t("menu.serviceManager")}
            to={urls.serviceManager.index()}
            onClick={onDrawerToggle}
          />
        ) : null}

        <MenuItem
          opened={opened}
          icon={<SupportIcon />}
          text={t("menu.helpdesk")}
          to={urls.helpdesk.index()}
          onClick={onDrawerToggle}
        />

        <MenuItem
          opened={opened}
          id="testMenuItemLogout"
          icon={<LogoutIcon />}
          text={t("menu.logout")}
          onClick={logOut}
        />
        {process.env.REACT_APP_ENVIRONMENT === "development" && (
          <Button color="primary" onClick={showTranslationKeys}>
            Show trans keys
          </Button>
        )}
      </List>
      {user.isInternal ? null : (
        <Box mt="auto">
          <UserBox
            opened={opened}
            id={user?.id}
            fullName={[user?.firstName, user?.lastName].join(" ")}
            role={getTranslatedRole(t, user?.role)}
            disabled={!authorizedReadUser}
          />
        </Box>
      )}
    </>
  );
};

export default Menu;

import { EActions, IOCTState, TReducers, EnvTypes } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

export const initialState: IOCTState = {
  modalOpen: false,
  loading: false,
  environment: EnvTypes.prod,
  otherEnv: "",
  trainingJobTag: undefined,
  traceId: null,
};

const reducers: TReducers = {
  [EActions.setModalOpen]: ({ payload: { modalOpen } }, { loading }) => ({
    modalOpen,
    loading: !modalOpen ? false : loading,
  }),
  [EActions.setEnv]: ({ payload: { environment } }) => ({
    environment,
  }),
  [EActions.setOtherEnv]: ({ payload: { otherEnv } }) => ({
    otherEnv,
  }),
  [EActions.setTrainingJobTag]: ({ payload: { trainingJobTag } }) => ({
    trainingJobTag,
  }),
  [EActions.setLoading]: ({ payload: { loading } }) => ({
    loading,
  }),
  [EActions.setXTraceId]: ({ payload: { traceId } }) => ({
    traceId,
  }),
};

export default createReducer<IOCTState>(path, initialState, reducers);

import React, { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import actions from "store/projectDetail/reports/actions";
import { useDispatch } from "react-redux";

interface TProps {
  recommendations: string;
  id: number;
  inputMaxLength: number;
  recommendationLengthError: string | null;
}
const { setRecommendations, setRecommendationLengthError } = actions;

export const Recommendation = memo(
  ({
    id,
    recommendations,
    inputMaxLength,
    recommendationLengthError,
  }: TProps) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const dispatch: any = useDispatch();

    const [recommendationValue, setRecommendationValue] =
      useState(recommendations);

    const handleChange = useCallback(
      ({
        target,
      }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = target;
        setRecommendationValue(value);
        dispatch(setRecommendations(value));

        if (value.length > inputMaxLength) {
          dispatch(
            setRecommendationLengthError(t("reports.recommendations.exceeded"))
          );
        } else {
          dispatch(setRecommendationLengthError(null));
        }
      },
      [dispatch, inputMaxLength, t]
    );

    return (
      <Box className={classes.inputWrapper}>
        <TextField
          label={t("reports.list.table.recommendationLabel")}
          value={recommendationValue}
          onChange={handleChange}
          variant="outlined"
          type="text"
          size="small"
          inputProps={{
            className: classes.input,
            min: 0,
          }}
          error={!!recommendationLengthError}
          helperText={
            !!recommendationLengthError ? recommendationLengthError : null
          }
          multiline
          rows={5}
          inputRef={inputRef}
        />
      </Box>
    );
  }
);

import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomPicker from "components/customPickers";
import Search from "components/search";
import Picker from "localization/pickerLocale";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import actions from "store/eventSounds/actions";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => ({
  root: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap",
    padding: theme.spacing(3, 0),
  },
  picker: {
    width: "100%",
  },
  input: {
    paddingTop: theme.typography.pxToRem(10.5),
    paddingBottom: theme.typography.pxToRem(10.5),
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
  },
}));

const DatasetClassFilter = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { startDate, endDate, q } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const dispatch: any = useDispatch();

  const onQSearch = useCallback(
    (event: any) => {
      dispatch(actions.setQ(event));
    },
    [dispatch]
  );

  const onChangeStart = useCallback(
    (date: Date | null) => {
      if (date) {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0);
        dispatch(actions.setCreationDateFrom(newDate));
      } else {
        dispatch(actions.setCreationDateFrom(null));
      }
    },
    [dispatch]
  );

  const onChangeEnd = useCallback(
    (date: Date | null) => {
      if (date) {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0);
        dispatch(actions.setCreationDateTo(newDate));
      } else {
        dispatch(actions.setCreationDateTo(null));
      }
    },
    [dispatch]
  );

  return (
    <>
      <Grid container spacing={1} className={classes.root}>
        <Grid
          item
          xs={12}
          sm={3}
          lg={2}
          sx={{
            "& .MuiInputBase-root": {
              paddingRight: "15px",
            },
          }}
        >
          <Picker
            Component={CustomPicker}
            value={startDate ?? null}
            onChange={onChangeStart}
            label={t("dataset.filter.startDate")}
            showTimeSelect={false}
            dateFormat="yyyy/MM/dd"
            id="dataset-start"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          lg={2}
          sx={{
            "& .MuiInputBase-root": {
              paddingRight: "15px",
            },
          }}
        >
          <Picker
            Component={CustomPicker}
            value={endDate ?? null}
            onChange={onChangeEnd}
            label={t("dataset.filter.endDate")}
            showTimeSelect={false}
            dateFormat="yyyy/MM/dd"
            id="dataset-end"
            minDate={startDate || undefined}
          />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <Search
            value={q as string}
            onSearch={onQSearch}
            placeholder={t("search.searchDataset")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DatasetClassFilter;

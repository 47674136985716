import React, { useState, useMemo } from "react";
import Divider from "components/typography/heading/divider";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import InfoBox from "./infoBox";
import { useQuery } from "react-query";
import getMachineSubcategory from "api/handlers/machine/getMachineSubcategory";
import getMachineCategory from "api/handlers/machine/getMachineCategory";
import getMachineCategories from "api/handlers/machine/getMachineCategories";
import getMachineSubcategories from "api/handlers/machine/getMachineSubcategories";
import Spinner from "components/spinner";
import {
  subcategoryKey,
  categoryKey,
  machineCategoriesKey,
  machineManufacturersKey,
  machineModelTypesKey,
  machineProductLinesKey,
} from "shared/uniqueQueryKeys";
import { IMachineList } from "types/machine";
import moment from "moment";
import { DATE_FORMAT } from "components/dataLabeling/constants";
import patchMachine from "api/handlers/machine/patchMachine";
import { useMutation } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import FooterConfirmationBar from "components/layout/footerConfirmationBar";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";
import getMachineManufacturers from "api/handlers/machine/getMachineManufacturers";
import getMachineModelTypes from "api/handlers/machine/getMachineModelTypes";
import getMachineProductLines from "api/handlers/machine/getMachineProductLines";

type TProps = {
  machine: IMachineList;
};

const BasicInformation = ({ machine: initialMachine }: TProps) => {
  const { t } = useTranslation();

  const canEditMachine = usePermissions([
    EPermissions.UPDATE_ANY_MACHINE,
    EPermissions.UPDATE_OWN_MACHINE,
  ]);

  const [machine, setMachine] = useState<IMachineList>(initialMachine);

  const { data: subcategoryData, status: subcategoryStatus }: any = useQuery(
    [subcategoryKey, machine.subcategory],
    () => getMachineSubcategory(machine.subcategory)
  );

  const { data: categoryData, status: categoryStatus }: any = useQuery(
    [categoryKey, subcategoryData],
    () => getMachineCategory(subcategoryData.category)
  );

  const [editing, setEditing] = useState<boolean>(false);
  const [machineSaved, setMachineSaved] =
    useState<IMachineList>(initialMachine);

  const [category, setCategory] = useState<number | null>(null);

  const { data: categoriesData }: any = useQuery(machineCategoriesKey, () =>
    getMachineCategories()
  );

  const categoryOptions = categoriesData
    ? categoriesData.results.map((i: any) => ({
        text: i.name,
        value: i.id,
      }))
    : null;

  const { data: subcategoriesData, status: subcategoriesStatus }: any =
    useQuery(category ? [machineCategoriesKey, category] : false, () =>
      getMachineSubcategories({ category: category! })
    );

  const subcategoryOptions = subcategoriesData
    ? subcategoriesData.results.map((i: any) => ({
        text: i.name,
        value: i.id,
      }))
    : null;

  const onChange = (name: string) => (value: any) =>
    setMachine((prev: IMachineList) => ({
      ...prev,
      [name]: value,
    }));

  const [patchMachineMutation] = useMutation(patchMachine);

  const saveMachine = () =>
    patchMachineMutation(
      {
        id: machine.id,
        payload: {
          name: machine.name,
          product_name: machine.product_name,
          commissioning: machine.commissioning,
          power: machine.power,
          machine_parts: machine.machine_parts,
          operational_information: machine.operational_information,
          additional_info: machine.additional_info,
          subcategory: machine.subcategory,
          machine_manufacturer: machine.machine_manufacturer,
          machine_model_type: machine.machine_model_type,
          machine_product_line: machine.machine_product_line,
        },
      },
      {
        onSuccess: (data: any) => {
          setEditing(false);
          setMachine(data);
          setMachineSaved(data);
        },
      }
    );

  const subcategorySelected = useMemo(
    () =>
      subcategoryOptions &&
      subcategoryOptions
        .map((i: any) => i.value)
        .indexOf(machine.subcategory) !== -1,
    [subcategoryOptions, machine.subcategory]
  );

  const {
    data: machineManufacturers,
    status: machineManufacturersStatus,
  }: any = useQuery(machineManufacturersKey, () => getMachineManufacturers());

  const { data: machineProductLines, status: machineProductLinesStatus }: any =
    useQuery(
      machine.machine_manufacturer || subcategoryData
        ? [
            machineProductLinesKey,
            machine.machine_manufacturer,
            subcategoryData,
          ]
        : false,
      () =>
        getMachineProductLines({
          manufacturer: machine.machine_manufacturer,
          subcategory: machine.subcategory,
        })
    );

  const { data: machineModelTypes, status: machineModelTypesStatus }: any =
    useQuery(
      machine.machine_product_line
        ? [machineModelTypesKey, machine.machine_product_line]
        : false,
      () =>
        getMachineModelTypes({
          product_line: machine.machine_product_line,
        })
    );

  const selectedMachineManufacturer = useMemo(
    () =>
      machineManufacturers &&
      machineManufacturers.results?.length &&
      machineManufacturers.results.find(
        (i: any) => i.id === machine.machine_manufacturer
      ),
    [machineManufacturers, machine.machine_manufacturer]
  );

  const selectedMachineProductLine = useMemo(
    () =>
      machineProductLines &&
      machineProductLines.results?.length &&
      machineProductLines.results.find(
        (i: any) => i.id === machine.machine_product_line
      ),
    [machineProductLines, machine.machine_product_line]
  );

  const selectedMachineModelType = useMemo(
    () =>
      machineModelTypes &&
      machineModelTypes.results?.length &&
      machineModelTypes.results.find(
        (i: any) => i.id === machine.machine_model_type
      ),
    [machineModelTypes, machine.machine_model_type]
  );

  React.useEffect(() => {
    if (categoryData && !editing) {
      setCategory(categoryData.id);
    }
  }, [categoryData, editing]);

  React.useEffect(() => {
    if (initialMachine.machine_manufacturer !== machine.machine_manufacturer) {
      onChange("machine_product_line")(null);
    } else {
      onChange("machine_product_line")(initialMachine.machine_product_line);
    }
  }, [machine.machine_manufacturer]);

  React.useEffect(() => {
    if (initialMachine.machine_product_line !== machine.machine_product_line) {
      onChange("machine_model_type")(null);
    } else {
      onChange("machine_model_type")(initialMachine.machine_model_type);
    }
  }, [machine.machine_product_line]);

  if (
    !editing &&
    (subcategoryStatus === "loading" ||
      !subcategoryData ||
      categoryStatus === "loading" ||
      !categoryData)
  ) {
    return <Spinner />;
  }

  const resetState = () => {
    setEditing(false);
    setMachine(machineSaved);
    setCategory(null);
  };

  if (!categoryOptions) {
    return <Spinner />;
  }

  return (
    <>
      <Divider line>
        {t("machine.detail.basicInformation")}
        {!editing && canEditMachine && (
          <IconButton
            style={{ margin: -12, left: 5 }}
            onClick={() => setEditing(true)}
          >
            <EditIcon
              fontSize="small"
              style={{ marginBottom: -5, marginTop: -5 }}
            />
          </IconButton>
        )}
      </Divider>
      <Box mb={1} display="flex" flexWrap="wrap">
        <InfoBox
          id="testMachineDetailBasicInformationName"
          title={t("machine.detail.machineName")}
          value={machine.name}
          onChange={editing && onChange("name")}
        />
        <InfoBox
          id="testMachineDetailBasicInformationCategory"
          title={t("machine.detail.machineCategory")}
          value={editing ? category : categoryData.name}
          onChange={editing && setCategory}
          options={categoryOptions}
        />
        <InfoBox
          id="testMachineDetailBasicInformationSubcategory"
          title={t("machine.detail.machineSubcategory")}
          value={
            editing
              ? subcategorySelected
                ? machine.subcategory
                : null
              : subcategoryData.name
          }
          onChange={editing && onChange("subcategory")}
          options={subcategoryOptions}
          loading={subcategoriesStatus === "loading"}
        />
      </Box>
      <Box mb={1} display="flex" flexWrap="wrap">
        <InfoBox
          id="testMachineDetailMachManufacturer"
          title={t("machine.detail.machineManufacturer")}
          value={
            editing
              ? (machine.machine_manufacturer as any)
              : selectedMachineManufacturer
                ? selectedMachineManufacturer.name
                : null
          }
          onChange={editing && onChange("machine_manufacturer")}
          options={
            machineManufacturers && machineManufacturers?.results?.length
              ? [
                  {
                    text: t("machine.detail.machineHierarchy.unassigned"),
                    value: "",
                  },
                  ...machineManufacturers?.results?.map((item: any) => ({
                    key: item.id,
                    value: item.id,
                    text: item.name,
                    tooltip: item.description,
                  })),
                ]
              : [
                  {
                    text: t("machine.detail.machineHierarchy.noItem"),
                    value: "",
                  },
                ]
          }
          loading={machineManufacturersStatus === "loading"}
        />
        <InfoBox
          id="testMachineDetailProductLine"
          title={t("machine.detail.machineProductLine")}
          value={
            editing
              ? (machine.machine_product_line as any)
              : selectedMachineProductLine
                ? selectedMachineProductLine.name
                : null
          }
          onChange={editing && onChange("machine_product_line")}
          options={
            machineProductLines &&
            machineProductLines?.results?.length &&
            machine.machine_manufacturer
              ? [
                  {
                    text: t("machine.detail.machineHierarchy.unassigned"),
                    value: "",
                  },
                  ...machineProductLines?.results?.map((item: any) => ({
                    key: item.id,
                    value: item.id,
                    text: item.name,
                    tooltip: item.description,
                  })),
                ]
              : [
                  {
                    text: t("machine.detail.machineHierarchy.noItem"),
                    value: "",
                  },
                ]
          }
          loading={machineProductLinesStatus === "loading"}
        />
        <InfoBox
          id="testMachineDetailModelType"
          title={t("machine.detail.machineModelType")}
          value={
            editing
              ? (machine.machine_model_type as any)
              : selectedMachineModelType
                ? selectedMachineModelType.name
                : null
          }
          onChange={editing && onChange("machine_model_type")}
          options={
            machineModelTypes &&
            machineModelTypes?.results?.length &&
            machine.machine_product_line
              ? [
                  {
                    text: t("machine.detail.machineHierarchy.unassigned"),
                    value: "",
                  },
                  ...machineModelTypes?.results?.map((item: any) => ({
                    key: item.id,
                    value: item.id,
                    text: item.name,
                    tooltip: item.description,
                  })),
                ]
              : [
                  {
                    text: t("machine.detail.machineHierarchy.noItem"),
                    value: "",
                  },
                ]
          }
          loading={machineModelTypesStatus === "loading"}
        />
        <InfoBox
          id="testMachineDetailProductName"
          title={t("machine.detail.machineProductName")}
          value={machine.product_name}
          onChange={editing && onChange("product_name")}
        />
        <InfoBox
          id="testMachineDetailCommissioning"
          title={t("machine.detail.machineCommissioning")}
          value={
            machine.commissioning
              ? moment(machine.commissioning).format(DATE_FORMAT)
              : null
          }
          onChange={editing && onChange("commissioning")}
          date
        />
        <InfoBox
          id="testMachineDetailPower"
          title={t("machine.detail.machinePower")}
          value={machine.power}
          unit="kW"
          onChange={editing && onChange("power")}
        />
      </Box>
      <Box mb={1} display="flex" flexWrap="wrap">
        <InfoBox
          id="testMachineDetailMachineParts"
          title={t("machine.detail.machineMachineParts")}
          value={machine.machine_parts}
          onChange={editing && onChange("machine_parts")}
          multiline
        />
        <InfoBox
          id="testMachineDetailOperationalInformation"
          title={t("machine.detail.machineOperationalInformation")}
          value={machine.operational_information}
          onChange={editing && onChange("operational_information")}
          multiline
        />
        <InfoBox
          id="testMachineDetailAdditionalInfo"
          title={t("machine.detail.machineAdditionalInfo")}
          value={machine.additional_info}
          onChange={editing && onChange("additional_info")}
          multiline
        />
      </Box>
      <FooterConfirmationBar
        onSubmit={saveMachine}
        discard={resetState}
        open={editing}
        disabled={!subcategorySelected}
        hideEditedInfo
      />
    </>
  );
};

export default BasicInformation;

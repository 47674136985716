import React from "react";
import { makeStyles } from "@mui/styles";
import { dateToDDMMYYYY, dateToHHMM } from "shared/helpers";
import NoData from "components/noData";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme) => {
  return {
    root: {
      transform: `translateY(${theme.spacing(0.5)})`,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    date: {
      fontWeight: theme.custom.typography.fontWeight.semiBold,
      fontSize: theme.custom.typography.fontSize[16],
    },
    hours: {
      fontSize: theme.custom.typography.fontSize[16],
      fontWeight: theme.custom.typography.fontWeight.normal,
      letterSpacing: theme.custom.typography.letterSpacing.medium,
    },
  };
});

interface Props {
  cell: {
    value: {
      date?: Date;
    };
  };
}

const DateAndTime = (props: Props) => {
  const {
    cell: {
      value: { date },
    },
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {!date && (
        <>
          <NoData />
        </>
      )}
      {date && (
        <>
          <span className={classes.date}>{dateToDDMMYYYY(date)}</span> -{" "}
          <span className={classes.hours}>{dateToHHMM(date)}</span>
        </>
      )}
    </div>
  );
};

export default DateAndTime;

export const userKey = "user";
export const usersKey = "users";
export const usersMoreKey = "usersMore";

export const projectsKey = "projects";
export const rolesKey = "roles";
export const companiesKey = "companies";
export const companyKey = "company";
export const projectKey = "project";

export const facilitiesOnboardingKey = "facilitiesOnboarding";
export const facilitiesKey = "facilities";
export const facilityKey = "facility";

export const machinesKey = "machines";
export const machineCategoriesKey = "machineCategories";
export const machineSubcategoriesKey = "machineSubcategories";
export const machineKey = "machine";
export const connectableMachinesKey = "connectableMachinesKey";
export const machineManufacturersKey = "machineManufacturers";
export const machineModelTypesKey = "machineModelTypes";
export const machineProductLinesKey = "machineProductLines";

export const eventsKey = "events";
export const eventTypesKey = "eventTypes";
export const eventModalKey = "eventModalKey";
export const eventLabelsKey = "eventLabelsKey";

export const deviceChannelsByUidKey = "deviceChannelsByUid";
export const deviceChannelsByIdKey = "deviceChannelsById";
export const devicesKey = "devices";
export const deviceVersionsKey = "deviceVersions";
export const deviceTypesKey = "deviceTypes";
export const deviceDetailKey = "deviceDetail";
export const deviceStatusesKey = "deviceStatusesKey";

export const placementTypesKey = "placementTypes";
export const placementsKey = "placements";

export const categoryKey = "category";
export const subcategoryKey = "subcategory";

export const getCheckTokenKey = "getCheckToken";

export const companiesModalKey = "companiesModalKey";
export const projectsModalKey = "projectsModalKey";

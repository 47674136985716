import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchFailureTypes } from "store/failureTypes/slice";
import { useFailureTypesSelect } from "store/failureTypes/selector";
import getPlacementFailures from "api/handlers/failures/getPlacementFailures";
import { SelectPopover } from "components/selectPopover";
import { TPlacementFailure } from "types/failureTypes";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import IMuiTheme from "types/styles";

type TFailureTypesSelectProps = {
  onChange: (val: any) => void;
  placementType: number;
  value?: number;
  disabled?: boolean;
  className?: string;
};

const useStyles = makeStyles((theme: IMuiTheme | Theme) => {
  return {
    title: {
      [theme.breakpoints.up("sm")]: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: theme.spacing(33),
        display: "inline-block",
      },
    },
  };
});

const FailureTypesSelect = (props: TFailureTypesSelectProps) => {
  const subscribed = useRef(true);
  const [failures, setFailures] = useState<TPlacementFailure[] | undefined>(
    undefined
  );
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const { data, loading } = useSelector(useFailureTypesSelect);
  const classes = useStyles();

  const getFailures = useCallback(async () => {
    if (props.placementType !== null) {
      const data = await getPlacementFailures(props.placementType);
      if (subscribed.current) {
        subscribed.current = false;
        setFailures(data);
      }
    }
  }, [props.placementType]);

  useEffect(() => {
    if (!data.length && !loading) {
      dispatch(fetchFailureTypes());
    }
    getFailures();
  }, [data, loading, dispatch, getFailures]);

  const filteredData = useMemo(() => {
    let filteredData = [
      {
        id: 0,
        name: t("eventModal.failures.other"),
      },
    ];

    if (data && failures) {
      Array.prototype.unshift.apply(
        filteredData,
        data
          .map((failure_type: any) => {
            return {
              id:
                failures.find(
                  (item: any) => item.failure_type === failure_type.id
                )?.id ?? 0,
              name: t(`failureType.${failure_type.name}`),
            };
          })
          .filter((failure: any) => failure.id > 0)
      );
    }

    return filteredData;
  }, [failures, data]);

  const selected = useMemo(() => {
    let item = filteredData?.find(
      (filtered: any) => filtered.id === Number(props.value)
    );

    return item ?? {};
  }, [filteredData, props.value]);

  return (
    <SelectPopover
      title={t(`devices.detail.list.cell.placement`)}
      selected={selected ?? ""}
      fetchData={getFailures}
      onChange={props.onChange}
      disabled={props.disabled}
      data={filteredData ?? []}
      loading={!filteredData}
      customcolor="black"
      titleClassName={classes.title}
    />
  );
};

export default FailureTypesSelect;

import React from "react";
import { makeStyles } from "@mui/styles";
import LogoHorizontal from "components/logotypes/horizontal";
import clsx from "clsx";

type Props = {
  children: React.ReactNode;
  hasError?: boolean;
  logoTargetUrl?: string;
};

const useStyles = makeStyles((theme: any) => {
  return {
    "@keyframes slideRight": {
      "8%, 41%": {
        transform: `translateX(${theme.typography.pxToRem(-10)})`,
      },
      "25%, 58%": {
        transform: `translateX(${theme.typography.pxToRem(10)})`,
      },
      "75%": {
        transform: `translateX(${theme.typography.pxToRem(-5)})`,
      },
      "92%": {
        transform: `translateX(${theme.typography.pxToRem(5)})`,
      },
      "0%, 100%": {
        transform: `translateX(${theme.typography.pxToRem(0)})`,
      },
    },
    root: {
      width: theme.spacing(64),
      maxWidth: "100%",
      margin: "0 auto",
      [theme.breakpoints.up("md")]: {
        paddingTop: "10vh",
      },
    },
    logoArea: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(5, 1),
      paddingTop: 0,
    },
    dialog: {
      backgroundColor: theme.custom.palette.surface,
      boxShadow: theme.custom.boxShadow[1],
      padding: theme.spacing(3),
      paddingTop: 0,
      border: theme.spacing(1),
      borderRadius: theme.spacing(1),
      margin: theme.spacing(0, 2, 10, 2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
      },
    },
    errorShake: {
      animationName: "$slideRight",
      animationDuration: ".5s",
    },
  };
});

const StaticDialog = (props: Props) => {
  const classes = useStyles(props);
  const { hasError } = props;
  return (
    <div className={classes.root}>
      <div className={classes.logoArea}>
        <a href="http://portal.neuronsw.com" target="_blank" rel="noreferrer">
          <LogoHorizontal />
        </a>
      </div>
      <div className={clsx(classes.dialog, hasError && classes.errorShake)}>
        {props.children}
      </div>
    </div>
  );
};

export default StaticDialog;

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Heading1 from "components/typography/heading/heading1";
import Paragraph from "components/typography/paragraph";
import QueryLink from "components/queryNavigator/link";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import urls from "shared/appUrls";
import { AppState } from "store";
import { setToken } from "store/auth/actions";

const LoggedInError: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();
  const dispatch: any = useDispatch();

  const user = useSelector((state: AppState) => state.auth.user);

  useEffect(() => {
    if (user?.isInternal) {
      const queryParams = new URLSearchParams(location.search);
      const accessToken = queryParams.get("token");
      if (accessToken) {
        dispatch(setToken(accessToken, "temporary_access_token"));
        history(urls.appRoot.index());
      }
    }
  }, [dispatch, location, history, user]);

  return (
    <>
      <Heading1
        icon={
          <QueryLink to={urls.appRoot.index()} path="machineFilter" exact>
            <ArrowBackIcon fontSize="large" />
          </QueryLink>
        }
      >
        {t("loggedInError.title")}
      </Heading1>
      <Paragraph>{t("loggedInError.text")}</Paragraph>
    </>
  );
};
export default LoggedInError;

import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function getMachineManufacturers() {
  const url = endpoints.machines.machineManufacturers;
  try {
    const { data } = await axiosNeuron.get(url);

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

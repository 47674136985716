import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { customModelTypes } from "enums/customModel";
import ButtonTabs from "components/buttonTab";
import CustomModelTraining from "./component/customModelTrainings";
import { useSelector } from "react-redux";
import { AppState } from "store";
import Spinner from "components/spinner";
import { useDispatch } from "react-redux";
import { fetchCustomModels } from "store/customModels/actions";
import CustomModelDeployment from "./component/customModelDeployment";
import Filter from "components/modelTable/customModel/filters";
import moment from "moment";

const CustomModels: React.FC = () => {
  const { t } = useTranslation();

  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, ""),
    from: withDefault(StringParam, ""),
    to: withDefault(StringParam, ""),
    status: withDefault(StringParam, ""),
    archived: withDefault(StringParam, ""),
    datasetName: withDefault(StringParam, ""),
    datasetId: withDefault(StringParam, ""),
    machineName: withDefault(StringParam, ""),
    customModelCategory: withDefault(StringParam, ""),
    deploymentEnvironment: withDefault(StringParam, ""),
  });

  const [from, setFrom] = useState<Date>(
    query.from ? new Date(query.from) : moment().subtract(1, "month").toDate()
  );

  const [to, setTo] = useState<Date>(
    query.to ? new Date(query.to) : new Date()
  );
  const [status, setStatus] = useState<string>(query.status || "all");
  const [archived, setArchived] = useState<string>(
    query.archived || "not_archived"
  );
  const [datasetName, setDatasetName] = useState<string>(query.datasetName);
  const [datasetId, setDatasetId] = useState<string>(query.datasetId);
  const [machineName, setMachineName] = useState<string>(query.machineName);
  const [customModelCategory, setCustomModelCategory] = useState<string>(
    query.customModelCategory
  );
  const [deploymentEnvironment, setDeploymentEnvironment] = useState<string>(
    query.deploymentEnvironment
  );

  const dispatch: any = useDispatch();

  const { data, isFetching } = useSelector(
    (state: AppState) => state.customModels.globalCustomModels
  );

  const [tab, setTab] = useState(query.tab || customModelTypes.trainings);
  const TabData = useMemo(() => {
    const tabs = [
      {
        label: t("models.detail.customModels.trainings"),
        value: customModelTypes.trainings,
      },
      {
        label: t("models.detail.customModels.deployments"),
        value: customModelTypes.deployments,
      },
    ];
    return tabs;
  }, []);

  const onChange = useCallback(
    (v: any) => {
      setQuery({ tab: v });
      setTab(customModelTypes[v as keyof typeof customModelTypes]);
    },
    [setQuery]
  );

  useEffect(() => {
    if (tab === customModelTypes.deployments) {
      setQuery(
        {
          from: from.toISOString(),
          to: to.toISOString(),
          status,
          archived,
          datasetName,
          datasetId,
          customModelCategory,
          machineName,
          deploymentEnvironment,
        },
        "pushIn"
      );
    } else {
      setQuery(
        {
          from: from.toISOString(),
          to: to.toISOString(),
          status,
          archived,
          datasetName,
          datasetId,
          customModelCategory,
          deploymentEnvironment,
        },
        "pushIn"
      );
    }
  }, [
    from,
    to,
    status,
    archived,
    datasetName,
    datasetId,
    customModelCategory,
    deploymentEnvironment,
    machineName,
    query,
    setQuery,
  ]);

  useEffect(() => {
    if (tab === customModelTypes.deployments) {
      dispatch(
        fetchCustomModels({
          from,
          to,
          status,
          modelArchived: archived,
          datasetName,
          datasetId,
          customModelCategory,
          deploymentEnvironment,
          machineName,
        })
      );
    } else {
      dispatch(
        fetchCustomModels({
          from,
          to,
          status,
          modelArchived: archived,
          datasetName,
          datasetId,
          customModelCategory,
          deploymentEnvironment,
        })
      );
    }
  }, [
    dispatch,
    from,
    to,
    status,
    archived,
    datasetName,
    datasetId,
    customModelCategory,
    deploymentEnvironment,
    machineName,
  ]);

  return (
    <>
      <>
        <Box my={2} width="fit-content">
          <ButtonTabs value={tab} tabs={TabData} onChange={onChange} />
        </Box>
        <Filter
          from={from}
          to={to}
          status={status}
          archived={archived}
          datasetName={datasetName}
          datasetId={datasetId}
          machineName={machineName}
          customModelCategory={customModelCategory}
          deploymentEnvironment={deploymentEnvironment}
          setFrom={setFrom}
          setTo={setTo}
          setStatus={setStatus}
          setArchived={setArchived}
          setDatasetName={setDatasetName}
          setDatasetId={setDatasetId}
          setMachineName={setMachineName}
          setCustomModelCategory={setCustomModelCategory}
          setDeploymentEnvironment={setDeploymentEnvironment}
          isDeployment={tab === customModelTypes.deployments}
        />
        {isFetching ? (
          <Spinner />
        ) : (
          <Box>
            {tab === customModelTypes.trainings ? (
              <CustomModelTraining data={data?.results} />
            ) : (
              <CustomModelDeployment data={data?.results} />
            )}
          </Box>
        )}
      </>
    </>
  );
};

export default CustomModels;

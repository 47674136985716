import { useCallback, useEffect, useState, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { isEqual } from "lodash";
import moment from "moment";
import momentTZ from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { urlFriendlyTZ } from "shared/helpers";
import {
  DATE_FORMAT,
  UTC,
  Local,
  TIME_FORMAT,
} from "components/dataLabeling/constants";
import { between } from "components/dataLabeling/helpers";
import Radio from "components/radio";
import Switch from "components/switch";
import { AppState } from "store";
import actions, { changeTimezone } from "store/machineDetail/sounds/actions";
import { useTranslation } from "react-i18next";
import ConfirmPopup from "../confirmPopup";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Box } from "@mui/material";
import CustomPicker from "components/customPickers";

interface Props {
  machine: number;
  setQuery: any;
}

export interface PlacementType {
  label: string;
  value: number;
  type: number;
  customName: string | null;
}

const useStyles = makeStyles((theme: any) => ({
  gridWrap: {
    flexWrap: "wrap",
    [theme.breakpoints.up("lg")]: {
      flexWrap: "nowrap",
      display: "flex",
      gap: "20px",
    },
  },

  date: {
    marginTop: "30px",
    marginRight: "10px",
    "& > div.MuiInputBase-root": {
      paddingRight: theme.typography.pxToRem(17),
    },
  },

  time: {
    marginTop: "30px",
    "& > div.MuiInputBase-root": {
      paddingRight: theme.typography.pxToRem(17),
    },
  },
  gridItem: {
    position: "relative",
    margin: 10,
    paddingTop: "16px",
    width: "167%",
    [theme.breakpoints.up(700)]: {
      width: "110%",
    },
    [theme.breakpoints.up(900)]: {
      width: "90%",
    },
    [theme.breakpoints.up(960)]: {
      width: "50%",
    },
    [theme.breakpoints.up(1400)]: {
      width: "30%",
    },
  },

  calendar: {
    width: "100%",
    "& > div > div > button:focus": {
      backgroundColor: theme.custom.palette.secondary,
    },
  },

  root: {
    width: "100%",
  },
  selectLabel: {
    marginBottom: "8px",
    width: "100%",
  },
  buttonGroup: {
    backgroundColor: theme.custom.palette.secondary,
    color: theme.custom.palette.primary["100"],
    boxShadow: theme.custom.boxShadow["2"],
  },
}));

const SoundsFilter = ({ machine, setQuery }: Props) => {
  const dispatch: any = useDispatch();
  const [downloadModal, showDownloadModal] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    localZoom,
    loadingData,
    renderFFT,
    dayData,
    timezoneOffset,
    duration,
    date,
    time,
    largeDownloadAllowed,
  } = useSelector((state: AppState) => state.machineDetail.sounds, isEqual);
  const [dateValue, setDateValue] = useState(date ?? "");
  const [timeValue, setTimeValue] = useState(date + time ?? "");
  const timeInputRef = useRef<HTMLInputElement>(null);

  // const hasAudioData = useCallback(
  //   (start: any, size: any) => {
  //     let end = start + size;

  //     for (let i = 0; i < dayData.length; i++) {
  //       let chunk = dayData[i];
  //       if (
  //         between(chunk.start, start, end) ||
  //         between(chunk.end, start, end) ||
  //         between(start, chunk.start, chunk.end) ||
  //         between(end, chunk.start, chunk.end)
  //       ) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [dayData]
  // );

  const handleTimezone = async (checked: boolean) => {
    let tz = checked ? UTC : Local;
    if (time) {
      const momentInput = date + " " + time;
      const addition = moment(momentInput).utcOffset();
      const dateToChange = checked
        ? momentTZ.tz(momentInput, timezoneOffset || Local).utc()
        : moment(momentInput).add(addition, "minutes");
      const dateFormatted = dateToChange.format("YYYY-MM-DD");
      const timeFormatted = dateToChange.format("HH:mm");
      dispatch(changeTimezone(dateFormatted, timeFormatted, tz));
    } else {
      dispatch(changeTimezone(date ? date.slice() : "", "", tz));
    }
  };

  const handleFFTSwitch = (checked: boolean) => {
    dispatch(actions.setFFT(checked));
  };

  const allowDownload = () => {
    dispatch(actions.setLargeDownloadAllowed(true));
    setQuery({
      date,
      timezoneOffset: urlFriendlyTZ(true, timezoneOffset),
      time,
      duration: 600,
    });
    dispatch(actions.setBulkZoom(1));
  };

  const declineDownload = () => {
    showDownloadModal(false);
  };

  const handleRenderPlayer = (e: any) => {
    e.persist();
    const base = 60;
    const newDuration = Number(e.target.value) * base;
    if (duration !== 600 && newDuration === 600 && !largeDownloadAllowed) {
      showDownloadModal(true);
      return;
    }
    setQuery({
      date,
      timezoneOffset: urlFriendlyTZ(true, timezoneOffset),
      time,
      duration: newDuration,
    });
  };

  useEffect(() => {
    if (time) {
      setTimeValue(date + time);
    }
    if (date) {
      setDateValue(date);
    }
  }, [time, date]);

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate?.isValid()) {
      setDateValue(dayjs(newDate, DATE_FORMAT));
    } else {
      setDateValue(null);
    }
  };

  const handleDateBlurChange = () => {
    const formatttedDateValue = dayjs(dateValue);
    if (!formatttedDateValue || !formatttedDateValue?.isValid()) return null;
    if (formatttedDateValue?.isValid()) {
      const formattedDate = moment(formatttedDateValue.toDate()).format(
        DATE_FORMAT
      );
      setQuery({
        timezoneOffset: urlFriendlyTZ(true, timezoneOffset),
        date: formattedDate,
        time: null,
      });
    }
  };

  const handleDateKeyDown = (event: any) => {
    if (!dateValue && !dateValue?.isValid()) return null;
    if (event.key === "Enter") {
      handleDateBlurChange();
      if (timeInputRef.current) {
        timeInputRef.current.focus();
      }
    }
  };

  const handleTimeChange = (newTime: Dayjs | null) => {
    if (newTime?.isValid()) {
      setTimeValue(dayjs(newTime, TIME_FORMAT));
    } else {
      setTimeValue(null);
    }
  };

  const handleTimeBlurChange = () => {
    const formatttedTimeValue = dayjs(timeValue);
    if (!formatttedTimeValue || !formatttedTimeValue?.isValid()) return null;
    if (dateValue && formatttedTimeValue && formatttedTimeValue?.isValid()) {
      const formattedDate = moment(dateValue).format(DATE_FORMAT);
      const formattedTime = formatttedTimeValue.format(TIME_FORMAT);
      setQuery({
        timezoneOffset: urlFriendlyTZ(true, timezoneOffset),
        date: formattedDate,
        time: formattedTime,
        duration,
      });
    }
  };

  const handleTimeKeyDown = (event: any) => {
    if (!timeValue && !timeValue?.isValid()) return null;
    if (event.key === "Enter") {
      handleTimeBlurChange();
      timeInputRef.current?.blur();
    }
  };

  return (
    <>
      {downloadModal && (
        <ConfirmPopup
          onConfirm={allowDownload}
          title={t("taggingapp.size.tenMinutes.title")}
          text={t("taggingapp.size.tenMinutes.text")}
          confirmText={t("taggingapp.size.tenMinutes.action")}
          noControl={true}
          onCancel={declineDownload}
        />
      )}
      {!loadingData && date && (
        <Box className={classes.gridWrap}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={classes.date}>
              <CustomPicker
                value={date ? date : null}
                onChange={handleDateChange}
                label={t("taggingapp.datePicker.label")}
                showTimeSelect={true}
                dateFormat="yyyy-MM-dd"
                id="dataLabelingDate"
                disableOpenPicker
                onBlur={handleDateBlurChange}
                onKeyDown={handleDateKeyDown}
              />
            </div>
            <div className={classes.time}>
              <CustomPicker
                value={
                  dayjs(timeValue).isValid()
                    ? dayjs(timeValue).toDate()
                    : dayjs(date + time).toDate()
                }
                onChange={handleTimeChange}
                label={t("taggingapp.timePicker.timeLabel")}
                showTimeSelect={true}
                dateFormat={`${TIME_FORMAT}:ss`}
                id="dataLabelingTime"
                disableOpenPicker
                onBlur={handleTimeBlurChange}
                onKeyDown={handleTimeKeyDown}
                isTimePicker
              />
            </div>
          </LocalizationProvider>

          <Box className={classes.gridItem}>
            <div>
              <Switch
                name="spectrumTZ"
                checked={timezoneOffset === UTC}
                labelOn={t("taggingapp.switch.utc")}
                labelOff={t("taggingapp.switch.localtime")}
                onChange={(event: any) => {
                  handleTimezone(event.target.checked);
                }}
                fixedSizeLeftLabel
              />
              <Switch
                name="spectrumSwitch"
                checked={renderFFT}
                labelOn={t("taggingapp.switch.spectrum.on")}
                labelOff={t("taggingapp.switch.spectrum.off")}
                onChange={(event: any) => {
                  handleFFTSwitch(event.target.checked);
                }}
                fixedSizeLeftLabel
              />
            </div>
            <div>
              {machine && date && dayData && (
                <>
                  <Radio
                    activeValue={String(duration / 60 / localZoom)}
                    value={`${10 / 60}`}
                    label="10 sec"
                    onChange={handleRenderPlayer}
                  />
                  <Radio
                    activeValue={String(duration / 60 / localZoom)}
                    value={"1"}
                    label="1 min"
                    onChange={handleRenderPlayer}
                  />
                  <Radio
                    activeValue={String(duration / 60 / localZoom)}
                    value={"3"}
                    label="3 min"
                    onChange={handleRenderPlayer}
                  />
                  <Radio
                    activeValue={String(duration / 60 / localZoom)}
                    value={"10"}
                    label="10 min"
                    onChange={handleRenderPlayer}
                  />
                </>
              )}
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SoundsFilter;

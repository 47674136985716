import { SortingRule } from "react-table";

import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IMachineList } from "types/machine";
import { ResponseType } from "types/response";
import { MachineCondition, MachineStatus } from "components/machine/types";

export interface IResponseTypeMachine<T extends object>
  extends ResponseType<T> {
  running: string;
  notRunning: string;
  unknown: string;
  maintenance: string;
  alerts: string;
  broken: string;
  outOfService: string;
}

interface Params {
  page?: number;
  pageSize?: number;
  order_by?: SortingRule<number>[];
  company?: number[];
  project?: number[];
  facility?: number[];
  ids?: number[];
  q?: string;
  show_archived?: boolean;
  running?: boolean;
  not_running?: boolean;
  unknown?: boolean;
  any_maintenance?: boolean;
  broken?: boolean;
  any_alert?: boolean;
  out_of_service?: boolean;
  field?: string[];
  subcategory?: number[];
}

export default async function getMachines(paramsInput: Params = {}) {
  const url = endpoints.machines.default;
  const orderByString = paramsInput.order_by?.length
    ? paramsInput.order_by.map(
        (item: any) => `${!!item.desc ? "-" : ""}${item.id}`
      )
    : [
        "-status__new_alerts",
        "-status__under_investigation_alerts",
        "-status__customer_informed_alerts",
        "-status__status",
        "name",
        "id",
      ];
  const params = {
    ps: paramsInput.pageSize || 500,
    page: paramsInput.page || 1,
    order_by: orderByString,
    status: [
      paramsInput.running ? MachineStatus.running : undefined,
      paramsInput.not_running ? MachineStatus.notRunning : undefined,
      paramsInput.unknown ? MachineStatus.unknown : undefined,
    ],
    facility: paramsInput.facility,
    company: paramsInput.company,
    project: paramsInput.project,
    condition: [
      paramsInput.any_maintenance ? MachineCondition.anyMaintenance : undefined,
      paramsInput.broken ? MachineCondition.broken : undefined,
      paramsInput.any_alert ? MachineCondition.anyAlert : undefined,
      paramsInput.out_of_service ? MachineCondition.outOfService : undefined,
    ],
    id: paramsInput.ids,
    q: paramsInput.q?.length ? paramsInput.q : undefined,
    is_archived: paramsInput.show_archived ? undefined : [false],
    field: paramsInput.field,
    subcategory: paramsInput.subcategory ? paramsInput.subcategory : undefined,
  };

  try {
    const response = await axiosNeuron.get(`${url}`, {
      params,
    });
    const data: IResponseTypeMachine<IMachineList> = {
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      running: response.data.running,
      notRunning: response.data.not_running,
      unknown: response.data.unknown,
      maintenance: response.data.any_maintenance,
      alerts: response.data.with_alerts,
      broken: response.data.broken,
      outOfService: response.data.out_of_service,
      results: response.data.results.map((item: any) => ({
        id: item.id,
        name: item.name,
        subcategory: item.subcategory,
        station: item.station,
        additional_info: item.additional_info,
        qrCode: item.qr_code,
        recordLength: item.record_length,
        recordingPeriod: item.recording_period,
        isActive: item.is_active,
        samplingFrequency: item.sampling_frequency,
        devices: item.devices,
        contactSet: item.contact_set,
        recordingStartDatetime: item.recording_start_datetime,
        recordingDaysRange: item.recording_days_range,
        recordingGateLevel: item.recording_gate_level,
        facilityId: item.facility,
        projectId: item.project,
        companyId: item.company,
        isSmsNotifying: item.is_sms_notifying,
        isEmailNotifying: item.is_email_notifying,
        status: item.status,
        alertsNew: item.new_alerts,
        alertsUnderInvestigation: item.under_investigation_alerts,
        alertsCustomerInformed: item.customer_informed_alerts,
        isMaintenance: item.is_maintenance,
        isBroken: item.is_broken,
        isNswMaintenance: item.is_nsw_maintenance,
        isAnyMaintenance: item.is_any_maintenance,
        isNominalSound: item.is_nominal_sound,
        isOutOfService: item.is_out_of_service,
        manufacturer: item.manufacturer,
        product_name: item.product_name,
        commissioning: item.commissioning,
        power: item.power,
        machine_parts: item.machine_parts,
        operational_information: item.operational_information,
        timeZone: item.time_zone,
        suppressNotificationsUntil: item.suppress_notifications_until,
        hasSnoozedPlacements: item.has_snoozed_placements,
        machine_manufacturer: item.machine_manufacturer,
        machine_model_type: item.machine_model_type,
        machine_product_line: item.machine_product_line,
      })),
    };
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

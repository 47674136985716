import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";

import StaticDialog from "components/staticDialog";
import Box from "@mui/material/Box";
import Heading4 from "components/typography/heading/heading4";
import Paragraph from "components/typography/paragraph";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import useData from "./preloadHook";

const Preloader = (props: any) => {
  const { t } = useTranslation();
  const serverErrorStatus = useSelector(
    (state: AppState) => state.serverError.status
  );
  const { loading } = useData();

  if (loading) {
    return <Spinner />;
  }

  if (serverErrorStatus) {
    return (
      <StaticDialog>
        <Box mb={4}>
          <Heading4>{t("serverError.title")}</Heading4>
        </Box>
        <Paragraph>{t("serverError.description")}</Paragraph>
      </StaticDialog>
    );
  }

  return <div>{props.children}</div>;
};

export default Preloader;

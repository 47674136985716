import { ignoreErrors as axiosNeuron } from "api/axios";
import endpoints from "api/endpoints";
import fetchLastModelActivity from "../fetchLastModelActivity";

interface ICustomModelsParams {
  from?: Date | null;
  to?: Date | null;
  customModelCategory?: string | null;
  status?: string | null;
  modelArchived?: string | null;
  datasetName?: string | null;
  datasetId?: number[] | any | null;
  machineName?: string | null;
  placement?: number[];
  machine?: number[];
  deploymentEnvironment?: string | null;
}

export default async function getCustomModels({
  from,
  to,
  customModelCategory,
  status,
  modelArchived,
  datasetName,
  datasetId,
  machineName,
  placement,
  deploymentEnvironment,
}: ICustomModelsParams) {
  const url = endpoints.customModelInfo.default;
  const params = {
    start_datetime: from ? from.toISOString() : undefined,
    end_datetime: to ? to.toISOString() : undefined,

    custom_model_category:
      customModelCategory !== "all" ? customModelCategory : undefined,
    ...(modelArchived === "archived" && { is_archived: true }),
    ...(modelArchived === "not_archived" && { is_archived: false }),
    ...(status === "deployed" && { is_running: true }),
    ...(status === "not_deployed" && { is_running: false }),
    ...(datasetId?.length ? { dataset_group: datasetId } : undefined),
    ...(machineName && { machine_name: machineName }),
    ...(datasetName && { dataset_group_name: datasetName }),
    deployment_env:
      deploymentEnvironment !== "all" ? deploymentEnvironment : undefined,
    placement: placement?.length ? placement : undefined,
  };
  try {
    const { data } = await axiosNeuron.get(`${url}`, { params });
    await fetchLastModelActivity(data.results);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw new Error(`Error fetching custom model information:${error}`);
    }
    throw error;
  }
}

/*eslint no-throw-literal: "error"*/
/* eslint-disable no-throw-literal */
export const lowerbound = (data: number[], value: number) => {
  let left = 0;
  let right = data.length;
  while (left < right) {
    const mid = Math.floor((left + right) / 2);
    if (data[mid] >= value) {
      right = mid;
    } else {
      left = mid + 1;
    }
  }
  return left;
};

export const interp1d = (x: number[], y: number[]) => {
  if (!x.length) {
    throw "No values";
  }
  if (x.length !== y.length) {
    throw "Lengths differ";
  }
  for (let i = 0; i < x.length - 1; ++i) {
    if (x[i] >= x[i + 1]) {
      throw "Values for x axis are not increasing";
    }
  }
  return (xnew: number[]) =>
    xnew.map((value: number) => {
      const lb = lowerbound(x, value);
      if (lb <= 0) {
        return y[0];
      }
      if (lb >= x.length) {
        return y[x.length - 1];
      }
      const coef = (value - x[lb - 1]) / (x[lb] - x[lb - 1]);
      return y[lb] * coef + y[lb - 1] * (1 - coef);
    });
};

// prettier-ignore
export const corrections = {
  bosch_nbox: {
    frequencies: [
      10.0, 11.0, 13.0, 16.0, 18.0, 22.0, 25.0, 30.0, 35.0, 41.0, 48.0, 57.0, 67.0, 78.0, 92.0, 108.0,
      126.0, 148.0, 174.0, 204.0, 239.0, 280.0, 329.0, 385.0, 452.0, 529.0, 621.0, 727.0, 853.0, 1000.0,
    ],
    corrections: [
      1356.724, 1356.724, 1356.724, 1356.724, 1356.724, 1356.724, 1356.724, 1213.755, 1000.279, 907.922,
      659.291, 556.07, 487.686, 412.549, 344.266, 310.058, 266.858, 218.159, 191.792, 178.011, 172.169,
      161.709, 146.387, 146.387, 146.387, 146.387, 146.387, 146.387, 146.387, 146.387,
    ],
    coefficient: 1,
  },
  bosch_nedge: {
    frequencies: [
      10.0, 11.0, 13.0, 16.0, 18.0, 22.0, 25.0, 30.0, 35.0, 41.0, 48.0, 57.0, 67.0, 78.0, 92.0, 108.0,
      126.0, 148.0, 174.0, 204.0, 239.0, 280.0, 329.0, 385.0, 452.0, 529.0, 621.0, 727.0, 853.0, 1000.0,
    ],
    corrections: [
      859.301, 859.301, 859.301, 859.301, 859.301, 859.301, 859.301, 936.043, 1136.916, 1431.969, 1432.734,
      1652.764, 1750.034, 1908.67, 1753.297, 1988.161, 1790.649, 1723.265, 1682.179, 1725.229, 1828.512,
      1817.336, 1758.638, 1758.638, 1758.638, 1758.638, 1758.638, 1758.638, 1758.638, 1758.638,
    ],
    coefficient: 1 / 12,
  },
  ctc_nedge: {
    frequencies: [
      10.0, 11.0, 13.0, 16.0, 18.0, 22.0, 25.0, 30.0, 35.0, 41.0, 48.0, 57.0, 67.0, 78.0, 92.0, 108.0,
      126.0, 148.0, 174.0, 204.0, 239.0, 280.0, 329.0, 385.0, 452.0, 529.0, 621.0, 727.0, 853.0, 1000.0,
    ],
    corrections: [
      874.105, 874.105, 874.105, 874.105, 874.105, 874.105, 874.105, 860.248, 819.015, 867.235, 780.461,
      813.732, 825.376, 800.723, 763.841, 788.923, 771.615, 736.608, 742.045, 747.434, 763.691, 736.567,
      735.249, 735.249, 735.249, 735.249, 735.249, 735.249, 735.249, 735.249,
    ],
    coefficient: 1,
  },
};

export const getSensorType = (sensorTypeCodename: string | null) => {
  if (!sensorTypeCodename) {
    return null;
  }
  if (sensorTypeCodename.indexOf("bosch") !== -1) {
    if (sensorTypeCodename.indexOf("iepe") !== -1) {
      return "bosch_nedge";
    } else {
      return "bosch_nbox";
    }
  } else if (
    sensorTypeCodename.indexOf("ctc") !== -1 ||
    sensorTypeCodename.indexOf("ronds") !== -1
  ) {
    return "ctc_nedge";
  } else {
    return null;
  }
};

export const correctFft = (
  sensorTypeCodename: string | null,
  real: Float32Array,
  imag: Float32Array,
  maxFrequency: number
) => {
  if (real.length !== imag.length) {
    throw "Lengths differ";
  }
  const sensorType = getSensorType(sensorTypeCodename);
  if (!sensorType) {
    return;
  }
  const correction = corrections[sensorType];
  const freqsHalf: number[] = Array.from(Array(real.length)).map(
    (value: number, index: number) => (index / real.length) * maxFrequency
  );
  const freqs = [...freqsHalf, ...freqsHalf.reverse()];
  const correctionValues = interp1d(
    correction.frequencies,
    correction.corrections
  )(freqs);
  real.set(
    real.map(
      (value: number, index: number) =>
        value * correctionValues[index] * correction.coefficient
    ),
    0
  );
  imag.set(
    imag.map(
      (value: number, index: number) =>
        value * correctionValues[index] * correction.coefficient
    ),
    0
  );
};

export const gainCorrections: { [key: number]: number } = {
  0: 1.0,
  20: 1.0,
  30: 8.4,
  40: 30,
  50: 100,
};

export const correctGainInBuffer = (
  sensorTypeCodename: string | null,
  buffer: AudioBuffer,
  gain: number
) => {
  const sensorType = getSensorType(sensorTypeCodename);
  if (!sensorType || sensorType !== "bosch_nbox") {
    return buffer;
  }
  const data = buffer.getChannelData(0);
  const gainCorrection = gainCorrections[gain];
  if (!gainCorrection) {
    throw "Invalid gain";
  }
  const gainCorrectionInv = 1 / gainCorrection;
  const mapped = data.map((value: number) => value * gainCorrectionInv);
  data.set(mapped, 0);
  return buffer;
};

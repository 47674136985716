import wasmModule from "./wasmfft";

export class WasmFFT {
  private module: any;

  private constructor(wasmModule: any) {
    this.module = wasmModule;
  }

  public static init(wasmModule: any): WasmFFT {
    return new WasmFFT(wasmModule);
  }

  public fft(direction: number, real: Float32Array, imag: Float32Array): void {
    if (real.length !== imag.length) {
      throw new Error("Real and imaginary arrays must have the same length.");
    }

    const length = real.length;
    const floatSize = real.BYTES_PER_ELEMENT;

    const realPtr = this.module._malloc(length * floatSize);
    const imagPtr = this.module._malloc(length * floatSize);

    this.module.HEAPF32.set(real, realPtr / floatSize);
    this.module.HEAPF32.set(imag, imagPtr / floatSize);

    this.module._fft(direction, realPtr, imagPtr, length);

    const resultReal = new Float32Array(
      this.module.HEAPF32.buffer,
      realPtr,
      length
    );
    const resultImag = new Float32Array(
      this.module.HEAPF32.buffer,
      imagPtr,
      length
    );

    const outputReal = new Float32Array(resultReal);
    const outputImag = new Float32Array(resultImag);

    this.module._free(realPtr);
    this.module._free(imagPtr);

    real.set(outputReal);
    imag.set(outputImag);
  }
}

var fftInstance: any;

(async () => {
  fftInstance = WasmFFT.init(await wasmModule());
})();

export const fft = (
  direction: number,
  real: Float32Array,
  imag: Float32Array
) => fftInstance.fft(direction, real, imag);

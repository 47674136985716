import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

interface Payload {
  is_sms_notifying?: boolean;
  is_email_notifying?: boolean;
  is_assisted_alerting?: boolean;
  suppress_notifications_until?: string | null;
  name?: string;
  is_generating_dashboards?: boolean;
  is_using_edge_loudness_model?: boolean;
  is_using_edge_models?: boolean;
  train_non_connected_placements?: boolean;
  time_zone?: string;
  production_mode?: string | null;
  manufacturer?: string | null;
  product_name?: string | null;
  commissioning?: string | null;
  power?: string | null;
  machine_parts?: string | null;
  operational_information?: string | null;
  additional_info?: string | null;
  subcategory?: number | null;
  machine_manufacturer?: number | any | null;
  machine_model_type?: number | any | null;
  machine_product_line?: number | any | null;
}

export default async function patchMachine({
  id,
  payload,
}: {
  id: number;
  payload: Payload;
}) {
  const url = endpoints.machines.detail(id);

  let targetPayload: { [key: string]: any } = { ...payload };
  for (let propName in targetPayload) {
    if (targetPayload[propName] === undefined) {
      delete targetPayload[propName];
    }
  }

  try {
    const { data } = await axiosNeuron.patch(url, targetPayload);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

import React, { PureComponent } from "react";
import Region from "./Region";
import RegionSimplified from "./RegionSimplified";
import * as utils from "./utils";
import RegionViewMode from "./RegionViewMode";
class Regions extends PureComponent {
  static defaultProps = {
    windowStart: 0,
    pxPerSec: 0,
    height: 0,
    bottom: 0,

    regions: [],
    selectedRegion: null,
    onSelectRegion: null,
    onRemoveAllLabels: null,
    onDragRegion: null,
    onResizeRegion: null,

    coef: 1.0,
  };

  render() {
    let {
      height,
      selectedRegion,
      onSelectRegion,
      onRemoveAllLabels,
      onDragRegion,
      onResizeRegion,
      hourView,
      editMode,
      drawState,
    } = this.props;
    let lines = this.splitIntoLines();
    let lineHeight =
      height / (editMode ? Math.max(5, lines.length) : lines.length);

    return (
      <React.Fragment>
        {lines.map((line, i) =>
          line.map((region) => {
            const regionColor =
              region.labels.find((label) => label.color)?.color || null;

            const selected =
              selectedRegion && this.equalRegions(selectedRegion, region)
                ? true
                : false;

            const hourViewRegionStyle = {
              background: regionColor ? `#${regionColor}` : "green",
              opacity: 0.5,
              border: "solid 2px #333333",
            };

            const hourViewSelectedRegionStyle = {
              background: regionColor ? `#${regionColor}` : "green",
              opacity: 0.9,
              border: "solid 2px #333333",
            };

            const regionStyle =
              drawState !== "spectrogram"
                ? {
                    background: regionColor ? `#${regionColor}` : "green",
                    opacity: 0.4,
                  }
                : {
                    background: regionColor ? `#${regionColor}` : "white",
                    opacity: 0.5,
                    border: "solid 2px #333333",
                  };

            const selectedRegionStyle =
              drawState !== "spectrogram"
                ? {
                    background: regionColor ? `#${regionColor}` : "green",
                    opacity: 0.8,
                  }
                : {
                    background: regionColor ? `#${regionColor}` : "white",
                    opacity: 0.9,
                    border: "solid 2px #333333",
                  };

            const defaultColor =
              drawState !== "spectrogram" ? "green" : "white";

            return editMode ? (
              <>
                {hourView ? (
                  <RegionSimplified
                    key={`${i}-${region.start}`}
                    style={hourViewRegionStyle}
                    selectedStyle={hourViewSelectedRegionStyle}
                    region={region}
                    id={`regSimp-id-${i}-${region.start}`}
                    {...this.calculationRegionPosition(i, lineHeight, region)}
                    selected={selected}
                    onDragRegion={onDragRegion}
                    onSelectRegion={onSelectRegion}
                    editMode={editMode}
                  />
                ) : (
                  <Region
                    key={`${i}-${region.start}`}
                    style={regionStyle}
                    selectedStyle={selectedRegionStyle}
                    region={region}
                    onRemoveAllLabels={onRemoveAllLabels}
                    selected={
                      selectedRegion &&
                      this.equalRegions(selectedRegion, region)
                        ? true
                        : false
                    }
                    {...this.calculationRegionPosition(i, lineHeight, region)}
                    {...(onSelectRegion ? { onClick: onSelectRegion } : {})}
                    id={`reg-id-${i}-${region.start}`}
                    onDragRegion={onDragRegion}
                    onResizeRegion={onResizeRegion}
                    onSelectRegion={onSelectRegion}
                    editMode={editMode}
                  />
                )}
              </>
            ) : (
              <>
                <RegionViewMode
                  key={`${i}-${region.start}`}
                  style={hourView ? "green" : defaultColor}
                  region={region}
                  onRemoveAllLabels={onRemoveAllLabels}
                  selected={
                    selectedRegion && this.equalRegions(selectedRegion, region)
                      ? true
                      : false
                  }
                  {...this.calculateRegionPositionForView(
                    i,
                    lineHeight,
                    region
                  )}
                  id={`reg-id-${i}-${region.start}`}
                />
              </>
            );
          })
        )}
      </React.Fragment>
    );
  }
  equalRegions({ start: s1, end: e1 }, { start: s2, end: e2 }) {
    return s1 === s2 && e1 === e2;
  }

  calculateRegionPositionForView(lineNo, lineHeight, region) {
    const { start, end } = region;
    let { windowStart, pxPerSec, bottom, coef, regions } = this.props;
    const isColliding = regions.some(
      (otherRegion) =>
        otherRegion !== region &&
        (utils.between(region.start, otherRegion.start, otherRegion.end) ||
          utils.between(region.end, otherRegion.start, otherRegion.end) ||
          utils.between(otherRegion.start, region.start, region.end) ||
          utils.between(otherRegion.end, region.start, region.end))
    );

    return {
      left: (start - windowStart) * pxPerSec * coef,
      width: (end - start) * pxPerSec * coef,
      bottom: bottom + lineNo * lineHeight + 1,
      height: isColliding ? lineHeight : "-webkit-fill-available",
    };
  }

  calculationRegionPosition(lineNo, lineHeight, region) {
    const { start, end } = region;
    let { windowStart, pxPerSec, bottom, coef } = this.props;
    return {
      left: (start - windowStart) * pxPerSec * coef,
      width: (end - start) * pxPerSec * coef,
      bottom: bottom + lineNo * lineHeight + 1,
      height: lineHeight - 1,
    };
  }

  splitIntoLines() {
    let { regions } = this.props;
    let lines = [[]];
    regions.forEach((region) => {
      let i = 0;
      for (; i < lines.length; i++) {
        if (!this.hasIntersection(lines[i], region)) {
          break;
        }
      }
      if (i === lines.length) {
        lines.push([]);
      }
      lines[i].push(region);
    });
    return lines;
  }

  hasIntersection(line, region) {
    for (let i = 0; i < line.length; i++) {
      let { start, end } = line[i];
      if (
        utils.between(region.start, start, end) ||
        utils.between(region.end, start, end) ||
        utils.between(start, region.start, region.end) ||
        utils.between(end, region.start, region.end)
      ) {
        return true;
      }
    }
    return false;
  }
}

export default Regions;

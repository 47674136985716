import React, { memo, useMemo, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useData from "dataHooks/projects/detailList";
import Box from "@mui/material/Box";
import Spinner from "components/spinner";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import { createColumns } from "components/projects/detail/facilityList/columns";
import { createTinyColumns } from "components/projects/detail/facilityList/tinyColumns";
import { IFacility } from "types/facility";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material//useMediaQuery";
import { useTranslation } from "react-i18next";
import { AddFacilityModal } from "components/projects/detail/modal";
import { useDispatch } from "react-redux";
import actions from "store/projectDetail/facilities/actions";
import urls from "shared/appUrls";
const { setModal, setReset } = actions;

export const FacilityTab = memo(() => {
  const { id } = useParams<{ [key: string]: string | undefined }>();
  const {
    facilities,
    reloading,
    modalOpened,
    loading,
    initialState,
    showMoreClickedTimesRef,
    onPageChange,
    onPageSort,
  } = useData(Number(id));
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
  const dispatch: any = useDispatch();
  const tinyColumnsMemoized = useMemo(() => createTinyColumns(t), [t]);
  const columnsMemoized = useMemo(() => createColumns(t), [t]);
  const columns = upMd ? columnsMemoized : tinyColumnsMemoized;
  const history = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(setReset());
    };
  }, [dispatch]);

  const closeModal = useCallback(() => {
    dispatch(setModal(false));
  }, [dispatch]);

  const handleRowClick = useCallback(
    (facilityID: any) => {
      navigate(urls.facilities.detail(facilityID), {
        state: { projectId: id },
      });
    },
    [history, id]
  );

  return (
    <>
      <Box>
        {reloading ? (
          <Spinner />
        ) : (
          <Table<IFacility>
            showMoreClickedTimesRef={showMoreClickedTimesRef}
            isLoading={loading}
            data={facilities?.results || []}
            rowsCount={facilities?.count || 0}
            columns={columns}
            initialState={initialState}
            onPageChange={onPageChange}
            onPageSort={onPageSort}
            RowComponent={Row}
            RowComponentProps={{
              onClick: (id?: number) => handleRowClick(id),

              rowHeight: theme.spacing(7),
              rowWidth: "100%",
            }}
            HeaderComponent={Header}
          />
        )}
      </Box>
      <AddFacilityModal open={modalOpened} onCancel={closeModal} />
    </>
  );
});

import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import moment from "moment";
import { getNormalizeTime } from "shared/helpers";
import { isNumber } from "lodash";

function convert(data: any) {
  let ret: any = {};
  const toTimestamp = (s: any) => moment(s).valueOf() / 1000;

  if (data) {
    data.forEach(
      ({ id, subcategory, start_datetime, end_datetime, meta }: any) => {
        const start = toTimestamp(getNormalizeTime(start_datetime));
        const end = toTimestamp(getNormalizeTime(end_datetime));
        const key = `${start}/${end}`;
        ret[key] = ret[key] || {};
        ret[key][subcategory] = true;
        ret[key].meta = meta;
        if (ret[key]["labels"]) {
          ret[key]["labels"].push({ subcategory, id });
        } else {
          ret[key]["labels"] = [{ subcategory, id }];
        }
        if (ret[key]["ids"]) {
          ret[key]["ids"].push(id);
        } else {
          ret[key]["ids"] = [id];
        }
      }
    );
  }

  ret = Object.keys(ret).map((key: any) => {
    const [start, end] = key.split("/").map((v: any) => v - 0);
    return {
      start,
      end,
      ids: ret[key]["ids"],
      labels: ret[key].labels,
      meta: ret[key].meta,
    };
  });
  return ret;
}

export async function getAudioRegions(
  machine: number,
  start: number,
  end: number,
  channel: number,
  timezoneOffset: string,
  beforeLabel?: number | boolean,
  afterLabel?: number | boolean
) {
  const start_time = moment.unix(start).format("YYYY-MM-DD HH:mm:ss.SSS");
  const end_time = moment.unix(end).format("YYYY-MM-DD HH:mm:ss.SSS");
  const url = endpoints.labels.default;
  const fullUrl = `${url}?machine=${encodeURIComponent(machine)}${
    channel ? `&placement=${encodeURIComponent(channel)}` : ``
  }${beforeLabel ? `` : `&from=${encodeURIComponent(start_time).replace(/\./g, "%2E")}`}${afterLabel ? `` : `&to=${encodeURIComponent(end_time).replace(/\./g, "%2E")}`}&tz=${encodeURIComponent(timezoneOffset)}&ps=500${
    beforeLabel && isNumber(beforeLabel)
      ? `&before_label=${beforeLabel}&order_by=-start_datetime&order_by=-id`
      : afterLabel && isNumber(afterLabel)
        ? `&after_label=${afterLabel}&order_by=start_datetime&order_by=id`
        : ``
  }`;
  try {
    const {
      data: { results },
    } = await axiosNeuron.get(fullUrl);

    return convert(results);
  } catch {
    return;
  }
}

import "./Labels.css";
import "./index.css";
import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

import Heading4 from "components/typography/heading/heading4";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "components/spinner";
import { urlFriendlyTZ } from "shared/helpers";
import { Local, DURATION } from "components/dataLabeling/constants";
import Placement from "./placement";
import { AppState } from "store";
import { fetchCommonData } from "store/machineDetail/sounds/actions";
import TimelineIcon from "@mui/icons-material/Timeline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useQueryParams,
  StringParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import { useTranslation } from "react-i18next";
import values from "lodash/values";
import Header from "./header";
import Filter from "./filters";
import useScrollToPlacement from "./useScrollToPlacement";
import Button from "components/button";
import { useNavigate } from "react-router-dom";
import appUrls from "shared/appUrls";
import {
  AnalysisTimeManagerData,
  prepareAnalysisUrl,
} from "components/analysisTimeManager/analysisTimeManager";
import AnalaysisTimeManagerModal from "components/machine/detail/grafanaDashboard/analysisTimeManagerModal";
import analysisTimeManagerActions from "store/machineDetail/analysisTimeManager/actions";
import { makeStyles } from "@mui/styles";

interface Props {
  machine: number;
  project: number;
}

const useStyles = makeStyles((theme) => ({
  lastBtn: {
    "& > div": {
      display: "flex",
    },
  },
  managetime: {
    "& > div": {
      "& > button": {
        "& > span.MuiButton-label": {
          flexWrap: "wrap",
        },
      },
    },
  },
}));

export interface PlacementType {
  label: string;
  value: number;
  type: number;
  customName: string | null;
}

const { setData: setAnalysisTimeManagerData } = analysisTimeManagerActions;

const LabelComponent = ({ machine }: Props) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const [
    {
      timezoneOffset,
      date,
      time,
      duration,
      channel: placement,
      soloPlacement: soloPlacementDefault,
    },
    setQuery,
  ] = useQueryParams({
    timezoneOffset: withDefault(StringParam, Local),
    date: StringParam,
    time: StringParam,
    duration: withDefault(NumberParam, DURATION),
    channel: StringParam,
    soloPlacement: withDefault(NumberParam, undefined),
  });
  const classes = useStyles();
  const history = useNavigate();
  const { data: analysisTimeManagerData } = useSelector(
    (state: AppState) => state.machineDetail.analysisTimeManager
  );

  const [manageTimesModalOpen, setManageTimesModalOpen] =
    useState<boolean>(false);
  const [addDate, setAddDate] = useState(false);
  const [analysisTimeManagerDataChange, setAnalysisTimeManagerDataChange] =
    useState<AnalysisTimeManagerData | null | undefined>(undefined);
  React.useEffect(() => {
    setAnalysisTimeManagerDataChange(analysisTimeManagerData);
  }, [analysisTimeManagerData]);

  const [soloPlacement, setSoloPlacement] = useState<number | null>(
    soloPlacementDefault ? soloPlacementDefault : null
  );

  React.useEffect(() => {
    if (manageTimesModalOpen) {
      setAnalysisTimeManagerDataChange(analysisTimeManagerData);
    }
  }, [manageTimesModalOpen, analysisTimeManagerData]);

  useScrollToPlacement(placement);

  const { yearData, placements } = useSelector(
    (state: AppState) => state.machineDetail.sounds,
    isEqual
  );

  useEffect(() => {
    dispatch(
      fetchCommonData(
        { tz: urlFriendlyTZ(false, timezoneOffset), date, time, duration },
        setQuery
      )
    );
  }, [timezoneOffset, dispatch, setQuery, date, time, duration]);

  const navigate = useCallback(
    (data: any) => {
      setQuery({
        ...data,
        timezoneOffset: urlFriendlyTZ(true, data.timezoneOffset),
      });
    },
    [setQuery]
  );

  const handleSoloPlacement = useCallback(
    (value: number | null) => {
      setSoloPlacement(value);
      setQuery({ soloPlacement: value ? value : undefined });
    },
    [setQuery, setSoloPlacement]
  );

  if (!yearData) {
    return <Spinner />;
  }

  if (yearData && isEmpty(yearData)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={1}
        p={10}
      >
        <Heading4>{t("list.noData")}</Heading4>
      </Box>
    );
  }

  const analysisButtonsDisabled = !(
    analysisTimeManagerData && analysisTimeManagerData.dates.length > 0
  );

  const handleDateClick = () => {
    setAddDate(!addDate);
  };

  const handleOpenAnalysis = () => {
    const analysisDates = analysisTimeManagerData
      ? prepareAnalysisUrl(analysisTimeManagerData)
      : undefined;
    const params = `?analysisPlacement=${analysisTimeManagerData.placement}&analysisSamples=${analysisTimeManagerData.samples}&analysisDates=${analysisDates}`;
    window.open(`${appUrls.machines.detailAnalysis(machine)}${params}`);
  };

  return (
    <>
      <Filter machine={machine} setQuery={navigate} />
      <Header navigate={navigate} />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-end"
        mt="20px"
      >
        <Box>
          <Button
            color="primary"
            onClick={handleDateClick}
            style={{ lineHeight: "1.5rem" }}
          >
            {addDate
              ? t("analysisTimeManager.cancelAddDate")
              : t("analysisTimeManager.addDate")}
          </Button>
        </Box>
        <Box ml={1}>
          <Button
            color="primary"
            disabled={analysisButtonsDisabled}
            onClick={() => dispatch(setAnalysisTimeManagerData(null))}
          >
            <DeleteIcon />
          </Button>
        </Box>
        <Box ml={1} className={classes.managetime}>
          <Button
            color="secondary"
            startIcon={<TimelineIcon />}
            disabled={analysisButtonsDisabled}
            onClick={() => setManageTimesModalOpen(true)}
            style={{ lineHeight: "1.5rem" }}
          >
            {t("analysisTimeManager.manageTime")}{" "}
            {analysisTimeManagerData &&
              analysisTimeManagerData?.dates?.length > 0 &&
              `(${analysisTimeManagerData?.dates.length})`}
          </Button>
        </Box>
        <Box ml={1}>
          <Button
            color="primary"
            disabled={analysisButtonsDisabled}
            onClick={() => history(appUrls.machines.detailAnalysis(machine))}
            style={{ lineHeight: "1.5rem" }}
          >
            {t("analysisTimeManager.goToAnalysis")}
          </Button>
        </Box>
        <Box ml={1} className={classes.lastBtn}>
          <Button
            color="primary"
            disabled={analysisButtonsDisabled}
            onClick={handleOpenAnalysis}
          >
            <OpenInNewIcon />
          </Button>
        </Box>
      </Box>
      <Grid>
        <Grid style={{ paddingTop: 15 }}>
          {values(placements)
            .filter((item: PlacementType) => {
              return soloPlacement === null || soloPlacement === item.value;
            })
            .map((item: PlacementType) => {
              return (
                <Placement
                  key={item.value}
                  placement={item}
                  navigate={navigate}
                  selected={placement === String(item.value)}
                  addDateActive={addDate}
                  setQuery={navigate}
                  soloPlacement={soloPlacement}
                  setSoloPlacement={handleSoloPlacement}
                />
              );
            })}
        </Grid>
      </Grid>
      <AnalaysisTimeManagerModal
        open={manageTimesModalOpen}
        setModalOpen={setManageTimesModalOpen}
        analysisTimeManagerDataChange={analysisTimeManagerDataChange}
        setAnalysisTimeManagerDataChange={setAnalysisTimeManagerDataChange}
      />
    </>
  );
};

export default LabelComponent;

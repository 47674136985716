import { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Select, { SelectProps } from "@mui/material/Select";
import Button from "components/button/index";
import MenuItem from "@mui/material/MenuItem";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import isNil from "lodash/isNil";

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: "100%",
  },
  relatedEventInput: {
    maxWidth: "50%",
  },
  loadMoreItem: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface Option {
  value: string | number;
  text: string;
}

type Props = SelectProps & {
  onChange: (val: string | number) => void;
  options: Option[];
  loading?: boolean;
  loadingMore?: boolean;
  loadMore?: () => void;
  size?: FormControlProps["size"];
};

export default memo(
  ({
    loadMore,
    loadingMore,
    loading,
    options,
    onChange,
    size,
    ...props
  }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const inputLabel = useRef<HTMLLabelElement>(null);

    const onSelect = useCallback(
      (e: any) => {
        if (!isNil(e.target.value)) {
          onChange(e.target.value);
          setOpen(false);
        }
      },
      [onChange]
    );

    const onLoadMore = useCallback(
      (e: any) => {
        e.stopPropagation();
        loadMore && loadMore();
      },
      [loadMore]
    );

    return (
      <FormControl variant="outlined" className={classes.root} size={size}>
        <InputLabel ref={inputLabel} htmlFor={props.id}>
          {props.label}
        </InputLabel>
        <Select
          open={open}
          labelId={props.id}
          label={props.label}
          onOpen={() => {
            setOpen(true);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            onClose: () => setOpen(false),
          }}
          onChange={onSelect}
          {...props}
        >
          {options.map((item: any) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
          {loadMore && (
            <MenuItem key="loadMore" className={classes.loadMoreItem}>
              <Button onClick={onLoadMore}>{t("loadMore")}</Button>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
);

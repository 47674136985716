import { createAsyncThunk } from "@reduxjs/toolkit";
import getEvents from "api/handlers/event/getEvents";
import getEvent from "api/handlers/event/getEvent";
import addEvent from "api/handlers/event/addEvent";
import patchEvent from "api/handlers/event/patchEvent";
import patchPairEvent from "api/handlers/event/patchPairEvent";
import getFailures from "api/handlers/failures/getFailures";
import { getPlacements } from "api/handlers/placement/getPlacements";
import { findPairEvent, resolveFilter } from "components/events/helpers";
import { EModalTypes, EDateMessageType, ELabelDateType } from "./types";
import { actions, updateFailures } from "./slice";
import moment from "moment";
import { AppState } from "store";
import { refetchEventsSilent } from "store/events/actions";
import { refetchMachinesSilent } from "store/machinesList/actions";
import i18n from "localization";
import { enqueueSnackbar } from "notistack";

const resolveEventInputs = (data: any, dispatch: any) => {
  if (data.type.indexOf("_end") === -1) {
    dispatch(
      actions.setTime({ type: ELabelDateType.start, time: data.created_at })
    );
    dispatch(
      actions.setMessage({
        type: EDateMessageType.start,
        message: data.note || "",
      })
    );
  } else {
    dispatch(
      actions.setTime({ type: ELabelDateType.end, time: data.created_at })
    );
    dispatch(
      actions.setMessage({
        type: EDateMessageType.end,
        message: data.note || "",
      })
    );
  }
};

export const fetchEventData = createAsyncThunk(
  "events/fetchEventData",
  async (
    {
      machineId,
      event,
    }: {
      machineId: number;
      event: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const { loading, eventId, modalType } = (getState() as AppState)
        .eventModal;

      if (!loading) {
        dispatch(actions.setLoading(true));

        const data = await getEvents({
          pageSize: 1,
          machine: machineId,
          eventTypes: resolveFilter(event),
          orderBy: [{ id: "created_at", desc: true }],
        });

        if (eventId) {
          let eventDetail = (await getEvent({
            id: eventId!,
          })) as any;
          dispatch(
            actions.setAlertStatusData({
              status: eventDetail.status,
              isCritical: eventDetail.is_critical,
              presumedCauses: eventDetail.presumed_causes,
              recommendedAction: eventDetail.recommended_action,
              anomalyTrend: eventDetail.anomaly_trend,
              diagDescription: eventDetail.diag_description,
            })
          );
          dispatch(
            actions.setInitialRelatedPairEvent({
              relatedPairEvent: eventDetail.related_pair_event,
            })
          );
          dispatch(
            actions.setIsHidden({
              isHidden: eventDetail.is_hidden,
            })
          );
          dispatch(
            actions.setEstimatedAmount({
              amount: eventDetail.estimated_prevented_damage_amount,
            })
          );
          dispatch(
            actions.setEstimatedCurrency({
              currency: eventDetail.estimated_prevented_damage_currency,
            })
          );
          let maintenance_type = eventDetail.maintenance_type;

          if (!maintenance_type && eventDetail.related_event) {
            if (eventDetail.related_event.maintenance_type) {
              maintenance_type = eventDetail.related_event.maintenance_type;
            }
          }

          dispatch(
            actions.setMaintenanceType({ maintenanceType: maintenance_type })
          );

          resolveEventInputs(eventDetail, dispatch);
          if (eventDetail?.created_at) {
            dispatch(
              actions.setTime({
                type: ELabelDateType.start,
                time: eventDetail.created_at,
              })
            );
            if (eventDetail.related_event) {
              resolveEventInputs(eventDetail.related_event, dispatch);
            }
          }
          dispatch(actions.setEventDetail(eventDetail));

          try {
            const realEventId =
              eventDetail.related_event && eventDetail.type.endsWith("_end")
                ? eventDetail.related_event.id
                : eventId;
            const failures = await getFailures(realEventId);

            const placements = (
              await getPlacements({ machineIds: [eventDetail.machine] })
            ).results;

            const placementIds =
              eventDetail.id === realEventId
                ? eventDetail.placements
                : eventDetail.related_event.placements;

            const placementItems: any[] = placements
              .filter((i: any) => placementIds.indexOf(i.id) !== -1)
              .map((i: any) => ({
                id: null,
                event: realEventId,
                placement: i.id,
                placementType: i.type,
                other_failure: null,
                failure: null,
                note: null,
              }));

            if ((failures || placementItems.length) && placements) {
              const addedPlacements = new Set<number>();
              const extendedFailures = failures
                ? failures.map((item: any) => {
                    const placement = placements.find(
                      (placement: any) => placement.id === item.placement
                    );
                    if (placement) {
                      addedPlacements.add(placement.id);
                      return { ...item, placementType: placement.type };
                    }
                    return item;
                  })
                : [];

              dispatch(
                actions.setFailures({
                  failures: extendedFailures.concat(
                    placementItems.filter(
                      (i: any) => !addedPlacements.has(i.placement)
                    )
                  ),
                })
              );
            }
          } catch (e) {
            throw new Error(`${e}`);
          }
        }

        const lastEvent = data?.count > 0 ? data?.results[0] : null;
        if (modalType !== EModalTypes.edit) {
          if (lastEvent && lastEvent.type.indexOf("_end") === -1) {
            dispatch(actions.setEventId({ eventId: lastEvent.id }));
            dispatch(actions.setEventDetail(lastEvent));
            dispatch(
              actions.setTime({
                type: ELabelDateType.start,
                time: lastEvent.createdAt
                  ? moment(lastEvent.createdAt).format()
                  : undefined,
              })
            );

            dispatch(actions.setModal({ modalType: EModalTypes.finish }));
            dispatch(
              actions.setEventType({
                eventType: findPairEvent(lastEvent.type)!,
              })
            );
          } else if (modalType !== EModalTypes.create) {
            dispatch(actions.setModal({ modalType: EModalTypes.create }));
          }
        }
        dispatch(actions.setLoading(false));

        return data?.results;
      }
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const fetchLastEventBeforeStart = createAsyncThunk(
  "events/fetchLastEventBeforeStart",
  async (
    {
      machineId,
      event,
      to,
      notEventId,
    }: {
      machineId: number;
      event: string;
      to: string;
      notEventId?: number[];
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(actions.setValidationLoading(true));
      const data = await getEvents({
        pageSize: 1,
        machine: machineId,
        eventTypes: resolveFilter(event),
        orderBy: [{ id: "created_at", desc: true }],
        timeIntervals: {
          to: moment(to).format("YYYY-MM-DD HH:mm:ss.SSSZ"),
        },
        preventCancel: true,
        not_id: notEventId,
      });
      dispatch(actions.setLastEventBeforeStart(data.results[0] || null));
      dispatch(actions.setValidationLoading(false));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const fetchFirstEventAfterEnd = createAsyncThunk(
  "events/fetchFirstEventAfterEnd",
  async (
    {
      machineId,
      event,
      from,
      notEventId,
    }: {
      machineId: number;
      event: string;
      from: string;
      notEventId?: number[];
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(actions.setValidationLoading(true));
      const data = await getEvents({
        pageSize: 1,
        machine: machineId,
        eventTypes: resolveFilter(event),
        orderBy: [{ id: "created_at", desc: false }],
        timeIntervals: {
          from: moment(from).format("YYYY-MM-DD HH:mm:ss.SSSZ"),
        },
        preventCancel: true,
        not_id: notEventId,
      });
      dispatch(actions.setFirstEventAfterEnd(data.results[0] || null));
      dispatch(actions.setValidationLoading(false));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const fetchOneEventBetweenStartAndEnd = createAsyncThunk(
  "events/fetchOneEventBetweenStartAndEnd",
  async (
    {
      machineId,
      event,
      from,
      to,
      notEventId,
    }: {
      machineId: number;
      event: string;
      from: string;
      to?: string;
      notEventId?: number[];
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(actions.setValidationLoading(true));
      const data = await getEvents({
        pageSize: 1,
        machine: machineId,
        eventTypes: resolveFilter(event),
        timeIntervals: {
          from: moment(from).format("YYYY-MM-DD HH:mm:ss.SSSZ"),
          to: to ? moment(to).format("YYYY-MM-DD HH:mm:ss.SSSZ") : undefined,
        },
        preventCancel: true,
        not_id: notEventId,
      });
      dispatch(actions.setEventBetweenStartAndEnd(data.results[0] || null));
      dispatch(actions.setValidationLoading(false));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const createEventData = createAsyncThunk(
  "events/createEventData",
  async (data: any, { dispatch }: { dispatch: any }) => {
    try {
      const { start } = (await addEvent(data)) ?? {};
      if (start?.id) {
        dispatch(updateFailures(start.id));
        enqueueSnackbar(i18n.t("eventModal.actions.success.create"));
        dispatch(refetchEventsSilent());
        dispatch(refetchMachinesSilent());
      }
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const updateEventData = createAsyncThunk(
  "events/updateEventData",
  async (
    { eventId, note, modalType }: any,
    { dispatch }: { dispatch: any }
  ) => {
    try {
      await patchEvent({ id: eventId, payload: { note } });
      dispatch(refetchEventsSilent());
      enqueueSnackbar(
        i18n.t(
          modalType === EModalTypes.finish
            ? "eventModal.actions.success.finish"
            : "eventModal.actions.success.edit"
        )
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const updatePairEventData = createAsyncThunk(
  "events/updatePairEventData",
  async (
    { modalType, id: eventId, ...data }: any,
    { dispatch }: { dispatch: any }
  ) => {
    try {
      await patchPairEvent({ id: eventId, ...data });
      enqueueSnackbar(
        i18n.t(
          modalType === EModalTypes.finish
            ? "eventModal.actions.success.finish"
            : "eventModal.actions.success.edit"
        )
      );
      dispatch(refetchEventsSilent());
      dispatch(refetchMachinesSilent());
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);
